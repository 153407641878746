import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class AlertsCasemanagementService {
  private baseUrl = environment.apiUrl;
  private spinner = new BehaviorSubject(false);
  spinnerShow = this.spinner.asObservable();


  private popUpObj = {
    showPopup: false,
    index: 0,
    loading: false,
    data: [],
    newData: false
  }
  private disputePopup = new BehaviorSubject(this.popUpObj);
  disputePopupShow = this.disputePopup.asObservable();

  constructor(private http: HttpClient) { }

  changeSpinner(message: boolean) {
    this.spinner.next(message)
  }

  changeDisputePopup(message: any) {
    this.disputePopup.next(message)
  }
  //Disputes Alerts
  getDisputeAlertList(userName, role) {
    let url = `${this.baseUrl}api/vendor_v2/getDisputeAlertList`;
    return this.http.post<any>(url, { userName: userName, role: role }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
         error);
      })
    );
  }

  getVendorDisputeDocument(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/getVendorDisputeDocument`;
    return this.http.post<any>(url, payLoad, { responseType: 'blob' as 'json' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
         error);
      })
    );
  }
  updateCaseService(payLoad: object) {
    let url = `${this.baseUrl}api/cases/updateCaseService`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
         error);
      })
    );
  }

}
