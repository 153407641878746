import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  /* Invoice */
  getRegionwiseInvoices(RmId, skipParam) {
    let url = `${this.baseUrl}api/rmDashboard/getRegionwiseInvoices`;
    return this.http.post<any>(url, { RmId: RmId, skipParam: skipParam }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  invoiceSearch(event) {
    let url = `${this.baseUrl}api/rmDashboard/invoiceSearch`;
    return this.http.post<any>(url, event).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /***** ****/


  /*Get Invoices List*/
  getInvoiceData(skipParam){
    let url=`${this.baseUrl}api/financeDashboard/getInvoiceData`;
    return this.http.post<any>(url,{skipParam:skipParam}).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
   /*Get Invoices List*/
   getRegionWiseInvoiceData(payload){
    let url=`${this.baseUrl}api/rmDashboard/getRegionwiseInvoices`;
    return this.http.post<any>(url,payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
   /*Get Invoices Based on User*/
  getInvoicesBasedOnUser(payload){
    let url=`${this.baseUrl}api/vendor_v2/getInvoicesBasedOnUser`;
    return this.http.post<any>(url,payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*Upload UTR data or vendor GST data*/

  uploadUTRorVendorGst(jsonData,endpoint){
    let url=`${this.baseUrl}`+endpoint;
    return this.http.post<any>(url,{jsonData}).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /*****Events ****/
  getcodes(payload:object) {
    let url = `${this.baseUrl}api/dependentList/getcodes`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  
  getCitiesBasedOnStates(payload:object){
    let url = `${this.baseUrl}api/dependentList/getCitiesBasedOnStates`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  
  saveEvents(payload:object){
    let url = `${this.baseUrl}api/rmDashboard/saveEvents`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getAllActiveEvents(payload:object){
    let url = `${this.baseUrl}api/supervisor/getAllActiveEvents`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getEventsReport(payload:object){
    let url = `${this.baseUrl}api/supervisor/getEventsReport`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*******Dispute Search *******/
  disputeSearch(payload:object){
    let url = `${this.baseUrl}api/vendor_v2/getDisputeDetails`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  uploadUTRorVendorGST(data){
    return this.http.post<any>(`${this.baseUrl}api/cases/updatePaymentInfoToService`, data).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  updateManualCNstatus(data){
    return this.http.post<any>(`${this.baseUrl}api/financeDashboard/createManualCN`, data).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
}
