import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommoncodesService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  /*CommonCode Details*/
  CommoncodeDetails() {
    return this.http.post<any>(`${this.baseUrl}api/commonCodes/getCommonCodes`, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /*for create common code*/
  createcommon(event) {
    let url = `${this.baseUrl}api/commonCodes/createOrUpdateCommonCodes`;
    return this.http.post<any>(url, { "codeType": event.codeType, "name": event.name, "code": event.code, "codeDesc": event.CodeDesc, "isActive": true, "createdBy": 'admin' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /*Delete Common Code*/
  deletecommon(event) {
    let url = `${this.baseUrl}api/commonCodes/deleteCommonCodes`;
    return this.http.post<any>(url, { "_id": event, "createdBy": 'admin' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /*Edit Common code */
  updatecommon(event) {
    let url = `${this.baseUrl}api/commonCodes/updateCommonCodes`;
    return this.http.post<any>(url, { "codeType": event.newData.codeType, "code": event.newData.code, "name": event.newData.name, "codeDesc": event.newData.codeDesc, isActive: event.newData.isActive, "_id": event.newData._id, "createdBy": event.newData.createdBy }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*Get Codes Based on Code Type*/
  getCodes(codeType) {
    return this.http.post<any>(`${this.baseUrl}api/commonCodes/getCommonCodesForType`, { "codeType": codeType }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  changePassword(userName, currentPassword, newPassword) {
    let url = `${this.baseUrl}api/common/changePassword`;
    return this.http.post<any>(url, { "userName": userName, "cPass": currentPassword, "nPass": newPassword }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  callCustomerLocationAPI(data) {
    let url = `${this.baseUrl}api/vendor-reg_v2/updateCustCurrentLatLongToDB`;
    return this.http.post<any>(url, data).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  forgotPassWord(payload) {
    let url = `${this.baseUrl}api/vendor-reg_v2/forgotPassword`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


  getDiagnostic(schemeType, chasisPrefix, vehicleModel) {
    let url = `${this.baseUrl}api/daignostics/getDiagnosticTree`;
    return this.http.post<any>(url, { "schemeType": schemeType, "chasisPrefix": chasisPrefix, "vehicleModel": vehicleModel }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  saveDiagnostic(data) {
    let url = `${this.baseUrl}api/daignostics/updateDiagnostics`;
    return this.http.post<any>(url, data).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  uploadDiagnosticsData(jsonData) {
    let url = `${this.baseUrl}api/daignostics/uploadDiagnosticData`;
    return this.http.post<any>(url, jsonData).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getWorkflowData() {
    let url = `${this.baseUrl}api/common/getAdminConfigList`;
    return this.http.post<any>(url, null).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getWorkflowFile(payload) {
    let url = `${this.baseUrl}api/common/getAdminConfigJson`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  uploadWorkflowFile(fileContent) {
    console.log(fileContent)
    // const formData = new FormData();
    // formData.append('file', fileContent);
    let url = `${this.baseUrl}api/common/uploadAdminConfigJson`;
    return this.http.post<any>(url, fileContent).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  resetPassword(payload) {
    let url = `${this.baseUrl}api/vendor-reg_v2/resetPassword`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
}