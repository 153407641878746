import { Component, OnDestroy, OnInit,AfterViewInit  } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { PwdChangeComponent } from '../../../pwd-change/pwd-change.component'; // replace with the actual path to your PwdChangeComponent

import { AuthenticationService } from '../../../_services';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit  {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Change Password' },  { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private authService: AuthenticationService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private dialogService: NbDialogService) {
  }



  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;


    this.menuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'userMenuTag'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => {
      if(title == "Log out"){
        this.authService.logout();
      }else if(title == "Change Password"){
        
        this.dialogService.open(PwdChangeComponent);
      }
    });

    
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.kate);

  this.authService.getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users);

    const { xl } = this.breakpointService.getBreakpointsMap();

    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  ngAfterViewInit() {
    this.toggleSidebar();
    

  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    sessionStorage.removeItem('currentUser');    
    window.location.href = "/login";
    // this.menuService.navigateHome();
    // return false;
  }
}
