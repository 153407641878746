import { Component, OnInit, enableProdMode } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';
import { NH_MENU_ITEMS } from './_menuLists/nh-menu';
import { RM_MENU_ITEMS } from './_menuLists/rm-menu';
import { LT_MENU_ITEMS } from './_menuLists/lt-menu';
import { ADMIN_MENU_ITEMS } from './_menuLists/admin-menu';
import { AuthenticationService } from './_services';
import { AGENT_MENU_ITEMS } from './_menuLists/agents-menu';
import { FINANCE_MENU_ITEMS } from './_menuLists/finance-menu';
import { SV_MENU_ITEMS } from './_menuLists/supervisor-menu';
import { TM_MENU_ITEMS } from './_menuLists/tm-menu';
import { ZM_MENU_ITEMS } from './_menuLists/zm-menu';
import { environment } from "../environments/environment";
import { USER_ROLES } from './_helpers/Constants';
import { User } from './_models/user';
import { Router } from '@angular/router';


@Component({
  selector: 'ngx-app',
  templateUrl: './app.component.html',
  //styleUrls:['../assets/global.component.scss']
})
export class AppComponent implements OnInit {

  public menu;
  public isLoggedIn: Boolean;

  constructor(private authService: AuthenticationService, private analytics: AnalyticsService, private seoService: SeoService, private router: Router) {
  }

  ngOnInit(): void {
    this.isLoggedIn = false;
    if (this.router.url != '/shareMyLocation') {

      this.authService.currentUser.subscribe(data => {
        this.updateinfo(data);
      });
    }


    //  let currentUser:any = this.authService.currentUserValue;

    // if (currentUser) {
    //     this.isLoggedIn = true;
    //    if(currentUser.role == "admin"){
    //       this.menu = ADMIN_MENU_ITEMS
    //    }else if(currentUser.role == "network head"){
    //     this.menu = NH_MENU_ITEMS;
    //    }

    // }

    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
    this.initGoogleApi();
    // if (environment.production) {
    //   enableProdMode();
    // }
  }

  updateinfo(data) {
    if (data) {
      this.isLoggedIn = true;
      if (data.role == "admin") {
        this.menu = ADMIN_MENU_ITEMS
      } else if (data.role == "network head") {
        this.menu = NH_MENU_ITEMS;
      } else if (data.role == "regional manager") {
        this.menu = RM_MENU_ITEMS;
      } else if (data.role == "legal team") {
        this.menu = LT_MENU_ITEMS;
      } else if (data.role == USER_ROLES.AGENT_ROLE) {
        this.menu = AGENT_MENU_ITEMS;
      } else if (data.role == USER_ROLES.FINANCE_ROLE) {
        this.menu = FINANCE_MENU_ITEMS;
      } else if (data.role == USER_ROLES.SUPERVISOR_ROLE) {
        this.menu = SV_MENU_ITEMS
      } else if (data.role == USER_ROLES.TERRITORY_ROLE) {
        this.menu = TM_MENU_ITEMS;
      } else if (data.role == USER_ROLES.ZONAL_ROLE) {
        this.menu = ZM_MENU_ITEMS;
      }
    } else {
      this.isLoggedIn = false;
      this.menu = null;
    }
  }


  /**
   * initGoogleApi
   */
  initGoogleApi() {

    window['initMap'] = () => {
      console.log('Google Maps Api loaded!');
    }

    if (!window.document.getElementById('google-map-script')) {
      var s = window.document.createElement("script");
      s.id = "google-map-script";
      s.type = "text/javascript";
      s.src = "https://maps.googleapis.com/maps/api/js?key=" + environment.googleMapsKey + "&libraries=places&language=en&callback=initMap";
      console.log(s.src);
      window.document.body.appendChild(s);
    }
  }



}

/*
<script src="https://maps.googleapis.com/maps/api/js?key=&libraries=places&language=en"></script>
*/