import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AgentAlertService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getAgentAlert(payload: object) {
    //let url = `${this.baseUrl}api/agentAlerts_v2/getAgentAlerts`;//old url 
    let url = `${this.baseUrl}api/agentAlerts_v2/getAgentAlerts_V2`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error);
        return throwError(error);
      })
    );
  }
  //service to get the logged agent open cases count

  getAgentAlertsCount(payload) {
    let url = `${this.baseUrl}api/common/agentAlertsCount`;

    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getClients(payload: object) {
    let url = `${this.baseUrl}api/common/getClientsList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getAgents(payload: object) {
    let url = `${this.baseUrl}api/AgentAlerts_v2/getAllAgents`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(error);
      })
    );
  }
  getServiceAlertsBasedOnVendorStatus(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getServiceAlertsBasedOnVendorStatus_v2`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getalertsDateRangeData(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getalertsDateRangeData_v2`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }

  getVendorFollowUpAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getVendorFollowupAgentAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getServiceCompletedAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getServiceCompletedAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getCompletedAlertsOnDateRange(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getCompletedAlertsOnDateRange`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }

  getDeferredAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getDeferredAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getAllOpenCasesAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getAllOpenCasesAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getIrsaAlerts(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getIrsaCases`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getAllCasesAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getAllCasesAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getMicroSiteAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getMicroSiteAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }

  unassignedMicrositesAlerts() {
    let url = `${this.baseUrl}api/agentAlerts_v2/getMicroSiteAlertsCount`;
    return this.http.post<any>(url, { createdBy: 'microUser' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getMoreThanTwoDenielServicesAlert(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getMoreThanTwoDenielServices`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getTimerAlertsServices(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getTimerAlertsServices`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getAllDraftCases(payload: object) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getAllDraftCases`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getActiveDeactivetypeAlerts(payload: object) {//networkhead
    let url = `${this.baseUrl}api/vendor_v2/getActiveDeActiveStypeAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getActivateOrInactivateAlertLists(payload: object) {//networkhead
    let url = `${this.baseUrl}api/vendor_v2/activateOrInactivateAlerList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getLocationsAlertList(payload: object) {//networkhead
    let url = `${this.baseUrl}api/vendor_v2/getLocationsAlertList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getactivateOrInactivateAlerList(payload: object) {//networkhead
    let url = `${this.baseUrl}api/vendor_v2/activateOrInactivateAlerList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getRateRevisionAlertList(payload: object) {//networkhead
    let url = `${this.baseUrl}api/vendor_v2/getRateRevisionAlertList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }

  getAlertList(payload: object) {//Financial Dashboard
    let url = `${this.baseUrl}api/vendor_v2/getAlertList`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }

  getAllActiveVendors(payload: object) {//RSA legal Team
    let url = `${this.baseUrl}api/vendor_v2/getAllActiveVendors`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getChatBotAlerts(payload) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getChatBotAlerts`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getChatBotAlertCount() {
    let url = `${this.baseUrl}api/agentAlerts_v2/getChatBotAlertsCount`;
    return this.http.post<any>(url, null).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getExternalCases(payload){
    let url = `${this.baseUrl}api/agentAlerts_v2/getAlertBasedForExternalApi`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getAllNotAssignedCases(payload) {
    let url = `${this.baseUrl}api/agentAlerts_v2/getAllNotAssignedCases`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getVendorForMSILedit(payload) {
    return this.http.post<any>(
      `${this.baseUrl}api/rmDashboard/getVendorForMSILedit`,
      payload,
    ).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  saveUpdatedMsilRate(payload) {
    return this.http.post<any>(
      `${this.baseUrl}api/rmDashboard/saveUpdatedMsilRate`,
      payload,
    ).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
       alert(error)
        return throwError(
         error);
      })
    );
  }
  getEAIMISResult(payload) {
    return this.http.post<any>(
      `${this.baseUrl}api/user/uploadMisReport`,
      payload
    ).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
         error);
      })
    );
  }
}
