import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { toBase64String } from "@angular/compiler/src/output/source_map";
import { pbkdf2 } from "crypto";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CaseBookingService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  searchCustomer(data) {
    return this.http.post<any>(
      `${this.baseUrl}api/customer/getCustomerSearch`,
      data
    );
  }

  //send DRSA link to customer
  sendDRSlink(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/sendDRlink`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  calculateCancelCharges(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/calculateCancelCharges`, payload)
  }
  //send DRlinkWhatsapp
  sendDRlinkWhatsapp(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/sendDRlinkWhatsapp`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  //calling 
  vendorDisplayFiltersSubType(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/vendorDisplayFilters`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  borderChargesAmountLimit(payload) {
    console.log(payload);
    return this.http.post<any>(`${this.baseUrl}api/commonCodes/borderChargesAmountLimit`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  //Customer Usage alert
  getCustomerUsageAlert(customerId) {
    return this.http.post<any>(`${this.baseUrl}api/customer/customerUsageAlert`, { customerRef: customerId }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  //get Cases for customer
  getCasesForCustomer(customerId) {
    return this.http.post<any>(`${this.baseUrl}api/customer/getCasesForCustomer`, { custId: customerId }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getManagerDetails(branchCode) {
    return this.http.get<any>(`${this.baseUrl}api/rmDashboard/getManagersData?branchCode=` + branchCode).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  vendorSearchList(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('CaseNumber', payload.caseNumber);
    headers = headers.set('ServiceNumber', payload.serviceNumber);
    headers = headers.set('Clientversion', "v2");
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/vendorSearchNew`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  vendorSearchAutoList(payload) {
    console.log("payload", payload)
    let headers = new HttpHeaders();
    headers = headers.set('CaseNumber', payload.caseNumber);
    headers = headers.set('ServiceNumber', payload.serviceNumber);
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/vendorAutoList`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert('Network Error; please try again later.')
        return throwError(
          'Network Error; please try again later.');
      })
    );
  }
  getVendorLocationsWithStatus(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/getVendorLocationsWithStatus`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getVendorLocationsStatus(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/getVendorLocationsStatus`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCase(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/getCase`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCustomerInfo(payload) {
    return this.http.post<any>(`${this.baseUrl}api/customer/getCustomerInfo`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  customerUsageAlert(payload) {
    return this.http.post<any>(`${this.baseUrl}api/customer/customerUsageAlert`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getcodes(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getcodes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  saveDesatisfactionReasons(payload) {
    return this.http.post<any>(`${this.baseUrl}api/csat/updateDissatisfiedReasons`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getDrsaCities() {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/get21NCitiesList`, "").pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getsubcodes(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getSubcodes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getSubChilds(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getSubChilds`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  createCaseNotes(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/createCaseNotes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  uploadCaseOrServiceDocuments(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/uploadCaseOrServiceDocuments`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getRouteDistanceFromGoogleAPI_v2(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getRouteDistanceFromGoogleAPI_v2`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getRouteDistanceFromGoogleAPI(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getRouteDistanceFromGoogleAPI`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCalculatedAmounts(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getCalculatedAmounts`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getVendorProfile(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendor_v2/getVendorProfile`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  createCase(payload) {
    /*
    let headers = new HttpHeaders();
    headers = headers.set('LoggedInUser', payload.agentUserName);
    */
    return this.http.post<any>(`${this.baseUrl}api/cases/createCase`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  editCase(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('LoggedInUser', payload.CreatedBy);
    return this.http.post<any>(`${this.baseUrl}api/cases/editCase`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getHistoryOfACase(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/getHistoryOfACase`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getVendorDisputeDocument(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/getVendorDisputeDocument`;
    return this.http.post<any>(url, payLoad, { responseType: 'blob' as 'json' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  viewMap(claimId, serviceId) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/getRouteMapImage`, { claimId: claimId, serviceId: serviceId }, { responseType: 'blob' as 'json' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


  getCustCurrentLatLong(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getCustCurrentLatLong`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  sendRequestForAddress(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendor-reg_v2/sendSmstocustomerforlocationinfo`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCustomerAdress(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getCustomerAdress`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getLatLon(payload) {
    return this.http.post<any>(`${this.baseUrl}api/user/getLatLon`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCityCoordinates(payload) {
    return this.http.post<any>(`${this.baseUrl}api/user/getCitypoints`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  nearestDealerSearch(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/nearestDealerSearch`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  createService(payload) {
    let headers = { "LoggedInUser": payload.agentUserName }
    return this.http.post<any>(`${this.baseUrl}api/cases/createService`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  dealerSearch(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/dealerSearch`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  vendorSelect(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('LoggedInUser', payload.serviceInfo.agentUserName);
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/vendorSelect`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getVendorCostsAndDistances(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/calculateAmountAndKmsForMultipleVendors_v2`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  UpdateServiceForCostAnalysis(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/updateServiceForCostAnalysis`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getDealerLocsFromGoogle(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch/getDealerLocsFromGoogle`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  editService(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/editService`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  sendManualSmsToCustomer(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/sendManualSmsToCustomer`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  // updateCaseService(payload): Observable<any> {
  updateCaseService(payload) {
    //alert(payload)
    let headers = { "LoggedInUser": payload.agentUserName }
    return this.http.post<any>(`${this.baseUrl}api/cases/updateCaseService`, payload, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  updateRejectedVendors(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/updateRejectedVendors`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  updateRejectedDealers(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/updateRejectedDropDealers`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  saveCustomerNotes(payload) {
    return this.http.post<any>(`${this.baseUrl}api/customer/saveCustomerNotes`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  activateCustomer(payload) {
    return this.http.post<any>(`${this.baseUrl}api/customer/activateCustomer`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  overRideService(payload) {
    return this.http.post<any>(`${this.baseUrl}api/cases/overRideService`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  saveBreakDownSurveyNote(payload) {
    return this.http.post<any>(
      `${this.baseUrl}api/cases/saveBreakDownSurveyNote`,
      payload,
    );
  }

  screenShotService(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/uploadCaseOrServiceDocuments`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getandshowSOP(payload) {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };

    return this.http.post<any>(
      `${this.baseUrl}api/cases/getandshowSOP`, payload, httpOptions
    );
  }

  // excessKmsNotAgreedByCustomer(payload): Observable<any>{
  excessKmsNotAgreedByCustomer(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendorSearch_v2/excessKmsNotAgree`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  sendPayULink(payload) {
    return this.http.post<any>(`${this.baseUrl}/api/common/sendPayULink`, payload,).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getRejectReasons() {
    var data = [
      // 'ASP is not answering the call.',
      // 'ASP\'s mobile was switched off / Not reachable.',
      // 'ASP\'s mobile was not  reachable.',
      // 'ASP asked to call after few minutes',
      // 'Towing Van is Busy.',
      // 'Towing Van is broken down.',
      // 'Technician is Busy .',
      // 'Technician is not available.',
      // 'Breakdown Location is far away from ASP location.',
      // 'ASP agreed to attend First, later denied.',
      // 'ASP Dosen\'t want to work with RSA.',
      // 'ASP has payment issue/agreement issue.',
      // 'ASP  has agreement issue.',
      // 'ASP said he will attend later.',
      // 'ASP  said he has closed RSA business.',
      // 'No Entry Issue.',
      // 'Improper proper tools / training to  attend the breakdown.',
      // 'Loading and  Unloading issue.',
      // 'ASP will provide service on chargable basis.',
      // 'Mismatch location/Number in MMI according to service provider.',
      // 'Unfavorable weather / road  conditions.',
      // 'ASP cannot  provide services due to public holiday.',
      // 'Updated by Vendor Automation Process',
      // 'Denied due to complete lockdown.',
      // 'Towing facility is not available.'
      'Reason 1',
      'Reason 2',
      'Reason 3',
      'Reason 4',
      'Reason 5',
      'Reason 6',
      'Reason 7',
      'Reason 8',
      'Reason 9',
     
    ];
    return data;
  }

  /* newly added for Decision Based matrix */

  initialQuestion(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/initialQuestion`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /* newly added for Decision Based matrix */
  questionBasedDecisionMatrix(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/questionBasedDecisionMatrix`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getPaymentapprovedBylist(payload) {
    return this.http.post<any>(
      `${this.baseUrl}api/dependentList/getSubCodes`,
      payload
    );
  }
  getPaymentapproveReasonList(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getSubCodes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  checkIRSAEligiblity(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/checkIRSAEligiblity`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCustodyCitiesList(payload) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getCustodyCitiesList`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getDataManagersMappedBasedOnDistrict(payload) {
    return this.http.post<any>(`${this.baseUrl}api/user/getDataManagersMappedBasedOnDistrict`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getActiveEvent(payload) {
    return this.http.post<any>(`${this.baseUrl}api/common/getActiveEvent`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  savegoogleMapsLogs(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/common/savegoogleMapsLogs`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  verifyPayuTxnId(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/cases/verifyPayuTxnId`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  payUReasons(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getSubCodes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  payUApprovedBy(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getSubCodes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  sendTransactionDetails(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/cases/updateServicePayment`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  callGoogleMapsApi(payload: any) {
    this.savegoogleMapsLogs(payload).subscribe(
      (response) => console.log(response),
      (err) => console.log(err)
    );
  }
  sendSmsToCustomer(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/csat/sendSmsForServiceUpdate`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error('A network error occurred:', error.error.message);
        } else {
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
        alert(error);
        return throwError(error);
      })
    );
  }

  getIRSAserviceTypes(payload: any) {
    return this.http.post<any>(`${this.baseUrl}api/dependentList/getIRSAServiceTypes`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error('A network error occurred:', error.error.message);
        } else {
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
        alert(error);
        return throwError(error);
      })
    );
  }
}
