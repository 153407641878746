import { Component, OnInit } from '@angular/core';
import { CommoncodesService } from '../_services/commoncodes.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  cnfPwd: any;
  newPwd: any;
  id: any;
  constructor(private commoncodes: CommoncodesService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  changePassword() {
    if (!this.newPwd || !this.cnfPwd) {
      alert("please enter password");
      return;
    }
    if (this.newPwd != this.cnfPwd) {
      alert("Password and confirm password should be same");
      return;
    }
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/;

    if (!passwordPattern.test(this.newPwd)) {
      // console.log('Password is valid');
      alert("Kindly Check The Notes");
      this.newPwd = '';
      this.cnfPwd = '';
      return;
    }
    let payload = {
      "password": this.newPwd,
      "token": this.id
    }
    this.commoncodes.resetPassword(payload).subscribe((res) => {
      if (res.status == "success") {
        alert(res.message)
        window.location.href = "/login";
      } else {
        alert(res.message)
      }
    })
  }

}
