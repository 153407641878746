<div class="mainPage">
    <div id="mainScreen" *ngIf="mainScreen">
        <!-- <div><img style="display:block;margin:0 auto;" src="{{logo}}"></div> -->
        <div class="cont col-md-12 col-sm-12" style="margin-top:12px;" id="restyles">
            <p> {{statementText}}</p>&nbsp;
            <div class="mainClass">
                <div class="stars" *ngFor='let element of questionsArray; let i = index'>
                    {{element.QuestionText}}
                    <div class="col-md-12 col-sm-3 col-xs-3">
                        <input [ngClass]="'starch  starch-5-'+(i)" id="' starch-5-'+(i)" type="radio"
                            name="'starch-'+(i)" (click)="selectStarFun(5,element._id,i,element.QuestionCategory)" />
                        <label [ngClass]="'starch  starch-5-'+(i)" for="' starch-5-'+(i)"><img
                                src="../../assets/images/imagefive.png"></label>
                        <input [ngClass]="'starch  starch-4-'+(i) " id="' starch-4-'+(i)" type="radio"
                            name="'starch-'+(i)" (click)="selectStarFun(4,element._id,i,element.QuestionCategory)" />
                        <label [ngClass]="'starch  starch-4-'+(i)" for="' starch-4-'+(i)"><img
                                src="../../assets/images/imagefour.png"></label>
                        <input [ngClass]="'starch  starch-3-'+(i)" id="' starch-3-'+(i)" type="radio"
                            name="'starch-'+(i)" (click)="selectStarFun(3,element._id,i,element.QuestionCategory)" />
                        <label [ngClass]="'starch  starch-3-'+(i)" for="' starch-3-'+(i)"><img
                                src="../../assets/images/imagethree.png"></label>
                        <input [ngClass]="'starch  starch-2-'+(i)" id="' starch-2-'+(i)" type="radio"
                            name="'starch-'+(i)" (click)="selectStarFun(2,element._id,i,element.QuestionCategory)" />
                        <label [ngClass]="'starch  starch-2-'+(i)" for="' starch-2-'+(i)"><img
                                src="../../assets/images/imagetwo.png"></label>
                        <input [ngClass]="'starch  starch-1-'+(i)" id="' starch-1-'+(i)" type="radio"
                            name="'starch-'+(i)" (click)="selectStarFun(1,element._id,i,element.QuestionCategory)" />
                        <label [ngClass]="'starch  starch-1-'+(i)" for="' starch-1-'+(i)"><img
                                src="../../assets/images/imageone.png"></label>
                    </div>
                    <div class="col-md-12 col-sm-3 col-xs-3">
                        <b class="aa1">5</b>
                        <b class="aa2">4</b>
                        <b class="aa3">3</b>
                        <b class="aa4">2</b>
                        <b class="aa5">1</b>
                    </div>
                    <div class="col-md-12 col-sm-3 col-xs-3">
                        <span class="a5">Excellent</span>
                        <span class="a4">Very Good</span>
                        <span class="a3">{{Good}}</span>
                        <span class="a2">Not Good</span>
                        <span class="a1">Poor</span>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <br>

        </div>
        <div id="commentBox" *ngIf="commentBox">
            <div class="cont" style="padding:0px 0px 10px 0px" id="recomment">
                <p style="margin-top:15px;color:red;" ng-if="commentsError">Please enter comments.</p>
                <p style="margin-top:15px;">Thank you for your feedback.</p>
                <p style="margin-top:15px;">{{commentText}}</p>
                <textarea class="textareacss form-control" ng-model="feedback" id="feedback" *ngIf="commentBox1"
                    placeholder="Leave a comment"></textarea><br>
                <!--p style="margin-top:20px; font-size: smaller">Thank you for your valuable feedback, we look forward to serve you better</p-->
            </div>
        </div>
        <div id="showButton" style="padding: 0px 0px 20px 0px;" *ngIf="showButton">
            <button id="subbutton" class="buttoncss" (click)="submitRatingFun()">SUBMIT</button>
        </div>
    </div>
    <div id="successscreen" *ngIf="successScreen" class="refdk">
        <div class="cont" style="padding:5% 0% 5% 0%" id="resuccess">
            <p style="margin-top:2px; font-size: 14px">Thank you for your valuable feedback, we look forward to
                serve you better.</p>
        </div>
    </div>
    <div id="errorScreen" *ngIf="errorScreen">
        <div class="cont" style="padding:20px 0px 20px 0px" id="refailure">
            <p style="margin-top:2px; font-size: 14px">feedback already submitted before, we would love to hear
                more from you.</p>
        </div>
    </div>
</div>