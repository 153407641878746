import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import {
  AuthGuard, ALERT_MODULE_ROLES, CASES_MODULE_ROLES,
  FINANCE_MODULE_ROLES, VENDOR_MODULE_ROLES,
  SUPERVISOR_MODULE_ROLES, ADMIN_MODULE_ROLES, RM_MODULE_ROLES
} from './_helpers/auth.guard';
import { SsoComponent } from './sso/sso.component';
import { PwdChangeComponent } from './pwd-change/pwd-change.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DroplocationComponent } from './droplocation/droplocation.component';
import { ShareMyLocationComponent } from './share-my-location/share-my-location.component';
import { FeedBackComponent } from './feed-back/feed-back.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';




const routes: Routes = [
  {
    path: 'alerts',
    loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule),
    canActivate: [AuthGuard],
    data: {
      expectedRole: ALERT_MODULE_ROLES
    }
  },
  {
    path: 'cases',
    loadChildren: () => import('./case-management/case-management.module').then(m => m.CaseManagementModule),
    canActivate: [AuthGuard],
    data: {
      expectedRole: CASES_MODULE_ROLES
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('./vendor-management/vendor-management.module').then(m => m.VendorManagementModule)
    , canActivate: [AuthGuard],
    data: {
      expectedRole: VENDOR_MODULE_ROLES
    }
  },
  {
    path: 'finance',
    loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)
    , canActivate: [AuthGuard],
    data: {
      expectedRole: FINANCE_MODULE_ROLES
    }
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./supervisor/supervisor.module').then(m => m.SupervisorModule)
    , canActivate: [AuthGuard],
    data: {
      expectedRole: SUPERVISOR_MODULE_ROLES
    }
  },
  {
    path: 'rm',
    loadChildren: () => import('./regional-manager/regional-manager.module').then(m => m.RegionalManagerModule)
    , canActivate: [AuthGuard],
    data: {
      expectedRole: RM_MODULE_ROLES
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      expectedRole: ADMIN_MODULE_ROLES
    }
  },
  { path: 'ssoauthentication', component: SsoComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'dropVehicleConfirmation', component: DroplocationComponent },
  { path: 'shareMyLocation.html', component: ShareMyLocationComponent },
  { path: 'feedback/:caseNumber/:client/:token', component: FeedBackComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'feedback/:caseNumber/:client/:token/:serviceId', component: FeedBackComponent },
  { path: '**', redirectTo: 'admin' } // otherwise redirect to home
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
