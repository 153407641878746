import { NbMenuItem } from '@nebular/theme';

export const RM_MENU_ITEMS: NbMenuItem[] = [

 
  {
    title:'Alerts',
    icon: 'bell-outline',
    children:[
      {
        title:'Registration Alerts',
        link:'/alerts/registration'
      },
      {
        title:'GC-PPC/FTSP Registration Alerts',
        link:'/alerts/gc-ppc-registration'
      },
      
      {
        title:'Existing Vendor GC-PPC Alerts',
        link:'/alerts/ex-gc-ppc-registration'
      },
      {
        title:'Dispute Alerts',
        link:'/alerts/dispute'
      },
      {
        title:'Existing Location Alerts',
        link:'/alerts/location'
      },
      {
        title:'KYC Alerts',
        link:'/alerts/kyc'
      }, 
      {
        title:'Location Contact Detail Updation',
        link:'/alerts/location-contact'
      },
      // {
      //   title:'Border Charges Alerts',
      //   link:'/alerts/border-charges'
      // }
  
      ]
  }, 
  {
    title:'Active Vendors',
    icon: 'person-done-outline',
    link:'/rm/active-vendors'  
},
{
  title:'DeActive Vendors',
  icon: 'person-done-outline',
  link:'/rm/inactive-vendors'  
},
{
  title:'Pending Vendors',
  icon: 'alert-circle-outline',
  link: '/vendors/pending-vendors'  
},
{
  title:'Completed Services',
  icon: 'checkmark-circle-outline',
  link:'/cases/services/completed-services-search'   
},
// {
//   title:'Completed Cases',
//   icon: 'map-outline',
//   link:'/networkhead/approvedVendors'  
// },
{
  title:'Invoices',
  icon: 'file-add',
  link:'/rm/invoices'  
},
{
  title: 'Managers Data',
  icon: 'person-done-outline',
  link: '/rm/managers'
}

];
