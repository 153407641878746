<!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"> -->
<div class="mainPage">
    <div class="wht-bg">
        <div class="logo-bg">
            <a class="logo" href="#"><img class="img-responsive" src="assets/images/sfa.png" /></a>
        </div>

    </div>
    <div class="lodinmidarea">
        <div class="container midarea">

            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="loginform">
                            <div class="login-inner otpscreen">
                                <!-- <div style="padding-bottom: inherit;">
                                    <div class="se-pre-con" id="loader" *ngIf="loader"></div>
                                    <a href="#"><img src="images/eai-logo.png" class="logo-height"></a>

                                </div> -->
                                <div id="otpForm">
                                    <div class="description">
                                    </div>
                                    <div *ngIf="validUrl">
                                        <p class="alrt">Please click on valid link</p>
                                    </div>
                                    <div *ngIf="vendorActiveServices">
                                        <p class="alrt">For this vendor active services not available</p>
                                    </div>
                                    <div id="serviceFrom">
                                        <form [formGroup]="vendorForm">
                                            <div *ngIf="serviceFrom" id="serviceFromId">
                                                <div class="col-md-12 col-sm-12 form-group">
                                                    <span class="error" *ngIf="serviceerrorMsg" style='color:red;'
                                                        id="serviceerrorMsg">
                                                        please select any Service.
                                                    </span>
                                                </div>
                                                <div class="col-md-12 col-sm-12 form-group">
                                                    <div class="form-group" *ngIf="serviceROSListShow">
                                                        <label for="otp" style="font-weight: bold !important;">Active
                                                            ROS
                                                            Services:</label>

                                                        <select class="form-control drop-arrow"
                                                            formControlName="activeRosService"
                                                            (change)="selectedServiceOnchange('ROS',$event)">
                                                            <option value="">Select</option>
                                                            <option *ngFor="let item of serviceROSList | orderBy:'item'"
                                                                value={{item.ServiceId}}>
                                                                {{item.ServiceId}} -
                                                                {{item.CustomerName}} - {{item.RegdNo}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" *ngIf="selectedROSServiceId">
                                                        <label for="otp" style="font-weight: bold !important;">Update
                                                            Status:</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="updateStatus" >
                                                    </div>
                                                    <div class="form-group" *ngIf="serviceTOWListShow">
                                                        <label for="otp" style="font-weight: bold !important;">Active
                                                            TOW
                                                            Services:</label>
                                                        <select class="form-control drop-arrow"
                                                            formControlName="activeTowService"
                                                            (change)="selectedServiceOnchange('TOW',$event)">
                                                            <option value="">Select</option>
                                                            <option *ngFor="let item of serviceTOWList | orderBy:'item'"
                                                                value={{item.ServiceId}}>
                                                                {{item.ServiceId}} -
                                                                {{item.CustomerName}} - {{item.RegdNo}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" *ngIf="selectedTOWServiceId">
                                                        <label for="otp" style="font-weight: bold !important;">Update
                                                            Status:</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="updateStatus" >
                                                        <!-- <select class="form-control" formControlName="updateStatus"
                                                            >
                                                            <option *ngFor="let item of serviceStatus" value="{{item}}">
                                                                {{item}}</option>
                                                        </select> -->
                                                    </div>
                                                    <div *ngIf="isSubmit" class="form-group submit">
                                                        <button   nbButton *ngIf="isSelectService" type="submit" class="btn btn-default portalbtnstyle"
                                                            (click)="selectedService()"
                                                           >SUBMIT</button>
                                                        <button  nbButton *ngIf="isSendOTPROS" type="submit" class="btn btn-default portalbtnstyle"
                                                           
                                                            (click)="selectedService('serviceCompletedROS')"
                                                            >SEND Verification Code</button>
                                                        <button nbButton *ngIf="isTrainingVideoId" class="btn btn-default portalbtnstyle"
                                                            data-toggle="modal" data-target="#myVideoModal"
                                                            (click)="openTriningVideo()" style="float:right;">Training
                                                            Video</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="mobileNumber">
                                                <div class="form-group">
                                                    <label for="otp" style="font-weight: bold !important;">Enter
                                                        Recipient Mobile Number:</label>
                                                    <input type="text" class="form-control" id="otp"
                                                        placeholder="Enter Recipient Mobile Number"
                                                        (keyup)="enterRecipientNumber($event)">
                                                    <span class="error" *ngIf="mobileerrorMsg" style='color:red;'
                                                        id="mobileerrorMsg">
                                                        please enter valid mobile number
                                                    </span>
                                                </div>
                                                <div class="form-group" *ngIf="showClientName">
                                                    <label for="name" style="font-weight: bold !important;">Enter
                                                        Recipient Name:</label>
                                                    <input type="text" class="form-control" id="Clientname"
                                                        placeholder="Enter Recipient Name"
                                                        (keyup)="enterRecipientName($event)">
                                                    <span class="error" *ngIf="clientNameErr" style='color:red;'
                                                        id="clientNameErr">
                                                        please enter recipient name
                                                    </span>
                                                </div>
                                                <div class="form-group submit">
                                                    <button type="submit" class="btn btn-default portalbtnstyle"
                                                        style="width: -webkit-fill-available;"
                                                        (click)="verifyPhoneNumber()">SEND Verification Code</button>
                                                </div>
                                            </div>
                                            <div *ngIf="otpVerify">
                                                <div class="form-group">
                                                    <label for="otp">Enter the
                                                        Verification Code sent to <span
                                                            style="font-weight: bold !important;">+91-{{enteredMobileNumber}}</span></label>
                                                    <input type="text" class="form-control" id="otp"
                                                        placeholder="Enter Verification Code" (keyup)="enterOtpFnction($event)">
                                                    <span class="error" *ngIf="otperrorMsg" style='color:red;'
                                                        id="otperrorMsg">
                                                        please enter valid Verification Code
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <label style="padding-left: 10px;"><span
                                                            style="color:#9e9999;">Don't
                                                            receive the Verification Code?
                                                        </span><a><span
                                                                style="font-weight: bold !important;color:#0c3b93; cursor: pointer;"
                                                                (click)="verifyPhoneNumber()">Resend
                                                                Verification Code</span></a></label>
                                                </div>
                                                <div class="form-group submit">
                                                    <button type="submit" class="btn btn-default portalbtnstyle"
                                                        style="width: -webkit-fill-available;"
                                                        (click)="verifyOtp()">SUBMIT</button>
                                                </div>
                                            </div>
                                            <div *ngIf="isVideoPlayerShow">
                                                <nb-card class="w-100">
                                                    <nb-card-header>
                                                        <div class="row">
                                                            <h4 class="set-header">Training Video</h4>
                                                            <button type="button" class="close close-btn-position"
                                                                (click)="videoPlayerCloseFn()">×</button>
                                                        </div>
                                                    </nb-card-header>
                                                    <nb-card-body>
                                                        <div><iframe width="550" height="400" id="VendortrainingVideo"
                                                                src="../../assets/images/illustration_login.png"
                                                                title="YouTube video player" frameborder="0"
                                                                allowfullscreen></iframe>
                                                        </div>
                                                    </nb-card-body>
                                                </nb-card>
                                            </div>


                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>