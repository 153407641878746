import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorManagementService } from '../_services';

@Component({
  selector: 'ngx-feed-back',
  templateUrl: './feed-back.component.html',
  styleUrls: ['./feed-back.component.scss']
})
export class FeedBackComponent implements OnInit {
  commentBox = false;
  errorScreen = false;
  successScreen = false;
  showButton = false;
  feedback: any = '';
  commentsError = false;
  caseToken: any;
  caseNumber: any;
  schemeType: any;
  serviceId: any = "";
  Token: any;
  ratingValue = 0;
  comment: any;
  ratingArray = [];
  questionsArray = [];
  Good = "Good";
  validUrl = false;
  commentText: any = "";
  statementText: any = "";
  logo = "";
  mainScreen: boolean = true;
  schemeTypeDesc: string;
  linkTwo: boolean;


  constructor(private route: ActivatedRoute, public vendorService: VendorManagementService) { }

  ngOnInit(): void {
    this.caseNumber = this.route.snapshot.paramMap.get('caseNumber');
    this.schemeType = this.route.snapshot.paramMap.get('client');
    this.Token = this.route.snapshot.paramMap.get('token');
    this.serviceId = this.route.snapshot.queryParams.optionalParam;
    if (!this.caseNumber || !this.schemeType || !this.Token) {
      this.validUrl = true;
      document.getElementById('selectService').style.display = 'none';
      return
    } else {
      this.intialRender();
    }

  }

  //initial render function to render logo and all based on sceme

  intialRender = () => {
    if (this.schemeType == "ROENRSA" || this.schemeType == "ROENRTL") {
      document.body.style.backgroundColor = "#141518";

      // document.getElementById("restyles").style.color = "#a0a0a0";
      // document.getElementById("restyles").style.backgroundColor = "#141518";
      // document.getElementById("recomment").style.color = "#a0a0a0";
      // document.getElementById("recomment").style.backgroundColor = "#141518";
      // document.getElementById("subbutton").style.color = "white";
      // document.getElementById("subbutton").style.backgroundColor = "#000000";
      // document.getElementById("resuccess").style.color = "#a0a0a0";
      // document.getElementById("resuccess").style.backgroundColor = "#141518";
      // document.getElementById("refailure").style.color = "#a0a0a0";
      // document.getElementById("refailure").style.backgroundColor = "#141518";
      const elementIds = ["restyles", "recomment", "subbutton", "resuccess", "refailure"];
      for (const id of elementIds) {
        const element = document.getElementById(id);
        if (element) {
          element.style.color = id === "subbutton" ? "white" : "#a0a0a0";
          element.style.backgroundColor = id === "subbutton" ? "#000000" : "#141518";
        }
      }
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.id = "reCss";
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '../../assets/re.css';
      link.media = 'all';
      head.appendChild(link);

    } else if (this.schemeType == "CitroenC5" || this.schemeType == "CitroenC5_Retail") {
      //this.Good = "Ok"+"\xa0\xa0";
      this.Good = "Ok";
    }
    switch (this.schemeType) {
      case "TAAIRSA":
        this.schemeTypeDesc = "Tata";
        break;
      case "ROENRSA":
        this.schemeTypeDesc = "Royal Enfield";
        break;
      case "ROENRTL":
        this.schemeTypeDesc = "Royal Enfield";
        break;
      case "MHDRVERSA":
        this.schemeTypeDesc = "Mahendra";
        break;
      case "ORIXRSA":
        this.schemeTypeDesc = "ORIX";
        break;
      case "GODRSA":
        this.schemeTypeDesc = "Go Digit";
        break;
      case "FIATRSA":
        this.schemeTypeDesc = "Fiat";
        break;
      case "HERORSA":
        this.schemeTypeDesc = "Hero";
        break;
      case "HeroRetail":
        this.schemeTypeDesc = "Hero";
        break;
      case "VOLKSWAGEN":
        this.schemeTypeDesc = "Volkswagen";
        break;
      case "VOLKSWAGEN_RETAIL":
        this.schemeTypeDesc = "Volkswagen";
        break;
      case "Volkswagen_Logistics":
        this.schemeTypeDesc = "Volkswagen";
        break;
      case "CitroenC5":
        this.schemeTypeDesc = "Citroen";
        break;
      case "CitroenC5_Retail":
        this.schemeTypeDesc = "Citroen";
        break;
      case "Jaguar":
        this.schemeTypeDesc = "Jaguar";
        break;
      case "Land_Rover":
        this.schemeTypeDesc = "Land_Rover";
        break;
      case "AXISPRIVATERSA":
        this.schemeTypeDesc = "AXIS";
        break;
      case "AUDI":
        this.schemeTypeDesc = "Audi";
        break;
      case "AUDI_RETAIL":
        this.schemeTypeDesc = "Audi";
        break;
      case "Audi_Logistics":
        this.schemeTypeDesc = "Audi";
        break;
      case "BajajCV":
        this.schemeTypeDesc = "Bajaj";
        break;
      case "BajajCV_Retail":
        this.schemeTypeDesc = "Bajaj";
        break;
      case "MHDR_3W_CV":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "MHDR_CV_Retail":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "MHDRRERSA":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "RELIANCE_RSA":
        this.schemeTypeDesc = "Reliance";
        break;
      case "PORSCHE":
        this.schemeTypeDesc = "Porsche";
        break;
      case "PORSCHE_RETAIL":
        this.schemeTypeDesc = "Porsche";
        break;
      case "Porsche_Logistics":
        this.schemeTypeDesc = "Porsche";
        break;
      case "SKODA":
        this.schemeTypeDesc = "Skoda";
        break;
      case "SKODA_RETAIL":
        this.schemeTypeDesc = "Skoda";
        break;
      case "Skoda_Logistics":
        this.schemeTypeDesc = "Skoda";
        break;
      case "MHDR_BOLERO_CV_Retail":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "BOLERO_Neo":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "BOLERO_Retail":
        this.schemeTypeDesc = "Mahindra";
        break;
      case "MG_MOTORS":
        this.schemeTypeDesc = "MG MOTORS";
        break;
      case "MG_RETAIL":
        this.schemeTypeDesc = "MG MOTORS";
        break;
      case "HDFCTAG":
        this.schemeTypeDesc = "HDFC";
        break;
      case "HDFCErgo":
        this.schemeTypeDesc = "HDFC";
        break;
      case "HDFC_ERGO_CV":
        this.schemeTypeDesc = "HDFC";
        break;
      case "GODAWARI_EV":
        this.schemeTypeDesc = "Godavari EV";
        break;
      case "OBEN_RSA":
        this.schemeTypeDesc = "OBEN RSA";
        break;
      case "SUZ_BIG_BIKES":
        this.schemeTypeDesc = "Suzuki";
        break;
      case "SUZUKI_MASS":
        this.schemeTypeDesc = "Suzuki";
        break;
      case "Atul_Auto_3WeV":
        this.schemeTypeDesc = "Atul Auto";
        break;
      case "Bajaj_Secure_EV":
        this.schemeTypeDesc = "Bajaj";
        break;
      case "ATHER_RSA":
        this.schemeTypeDesc = "Ather";
        break;
      case "Alti_EV_Retail":
        this.schemeTypeDesc = "Altigreen";
        break;
      default:
        this.schemeTypeDesc = this.schemeType;
    }
    this.logo = 'images/' + this.schemeType + '.png';
    if (this.serviceId != undefined && this.serviceId != "" && this.serviceId != null) {
      //this.serviceId = caseToken[3];
      this.linkTwo = true;
    } else {
      this.linkTwo = false;
    }
    this.statementText = 'Thank you for availing ' + this.schemeTypeDesc + ' Roadside Assistance against your case ID- ' + this.caseNumber;
    if (this.schemeType == "ROENRSA" || this.schemeType == "ROENRTL") {
      //this.statementText = 'Thank you for calling '+this.schemeTypeDesc+' Support. ';
      this.statementText = 'Thank you for availing our roadside assistance. Your service request number is ' + this.caseNumber;
    }

    this.vendorService.validateUrl(this.caseNumber, this.Token).subscribe((res) => {
      if (res.status == 'success') {
        this.successScreen = false;
        this.mainScreen = true;
      } else {
        this.mainScreen = false;
        this.successScreen = true;
        //this.mainScreen = true;
      }
    });

    if (!this.linkTwo) {
      this.vendorService.getSchemeQuestions(this.schemeType, this.caseNumber).subscribe((questionRes) => {
        if (questionRes.status == "success") {
          this.questionsArray = questionRes.message
          this.questionsArray.sort(function (a, b) {
            return a.QuestionCategory.localeCompare(b.QuestionCategory);
          });
          if (this.questionsArray.length > 1) {
            this.questionsArray.push({
              "_id": undefined,
              "SchemeType": "Default",
              "QuestionCategory": "overallExperience",
              "QuestionText": "Please give your overall rating?"
            });
          }
        }
      });
    } else {
      this.questionsArray.push({
        "_id": undefined,
        "SchemeType": "Default",
        "QuestionCategory": "Service",
        "QuestionText": "please rate us for service " + this.serviceId + "?"
      });
    }
  }

  /************* function called when user selects star ************/

  selectStarFun = function (value, id, index, category) {
    // if(schemeType == "ROENRSA" || schemeType == "ROENRTL")
    // this.questionsArray[0].QuestionText='Please rate Your Overall Experience with us?'
     this.feedback = value;
    ///defining cluster clients
    let clustClientsArray = ["PIGORSA", "FGIRSA", "ShriRamGenIns", "GODRSA",
      "YMHARSA", "HDFCErgo", "CHOLA-2Wheelers", "CHOLA-4Wheelers", "LIBERTY-4Wheelers", "LIBERTY-2Wheelers", "LIBERTYCOMMERCIALRSA",
      "IFFCOTOKIO", "BAXARSA", "ICICI_Lombard", "MAGMAHDI", "RAHEJA_4W_RSA", "TRIUMPHRSA", "BENEINDRSA",
      "ORIXRSA", "CPP_INDIA", "L&T-FinanceRSA", "DUCATIRSA", "NAGIRSA", "UnitedIndiaRSA", "KWSIRSA", "OASSRSA", "INDIANMOTORCYCLESRSA"]
    if (!this.linkTwo) {
      if (((value == 4 || value == 5) && category == 'overallExperience') || ((value == 4 ||
        value == 5) && this.questionsArray.length == 1)) {
        this.commentBox = true;
        this.showButton = true;
        //this.commentBox1 = false;
        this.commentBox1 = true;
        this.commentText = "This will help us to keep you delighted at all times.";
        if (this.schemeType == "VOLKSWAGEN" || this.schemeType == "VOLKSWAGEN_RETAIL" || this.schemeType == "Volkswagen_Logistics") {
          this.commentText = "What according to you will help us deliver a great experience every time?";
        } else if (this.schemeType == "CitroenC5" || this.schemeType == "CitroenC5_Retail") {
          this.commentText = "What according to you will help us deliver a great experience";
        } else if (clustClientsArray.indexOf(this.schemeType) > -1) {
          this.commentText = "This will help us to keep you delighted at all times"
        }
      } else if (((value == 3) && category == 'overallExperience') || ((value == 3) && this
        .questionsArray.length == 1)) {
        this.commentBox = true;
        this.showButton = true;
        this.commentBox1 = true;
        this.commentText =
          "Can you please tell us how we could have met your expectations better?"
        if (clustClientsArray.indexOf(this.schemeType) > -1) {
          this.commentText = "This will help us to keep you delighted at all times"
        }
      } else if (((value == 1 || value == 2) && category == 'overallExperience') || ((value ==
        1 || value == 2) && this.questionsArray.length == 1)) {
        this.commentBox = true;
        this.showButton = true;
        this.commentBox1 = true;
        this.commentText =
          "Can you please tell us where  you were most disappointed in your experience with us?"
        if (clustClientsArray.indexOf(this.schemeType) > -1) {
          this.commentText = "Can you please share feedback on areas where we  can improve for better customer experience"
        }
      }

    } else {
      this.commentBox = false;
      this.showButton = true;
    }
    for (var i = 0; i < this.ratingArray.length; i++) {
      if (this.ratingArray[i].QuestionRef == id) {
        this.ratingArray.splice(i, 1);
        i--;
      }
    }
    if (value != 1 && value != 2 && value != 3 && value != 4 && value != 5) {
      value = 0;
    }
    this.ratingArray.push({
      "CaseID": this.caseNumber,
      "ServiceID": this.serviceId,
      "QuestionRef": id,
      "QuestionCategory": category,
      "CSATScore": value,
      "CustomerComments": ""
    });
  }

  submitRatingFun = function () {
    if (this.questionsArray.length == this.ratingArray.length) {
      this.commentsError = false;
      if (this.feedback == undefined || this.feedback == "" || this.feedback == null) {
        //alert('Please enter comments');
        this.commentsError = true;

        return false;
      }

      if (this.questionsArray.length == 1) {
        this.ratingArray.push({
          "CaseID": this.caseNumber,
          "ServiceID": this.serviceId,
          "QuestionRef": undefined,
          "QuestionCategory": "overallExperience",
          "CSATScore": this.ratingArray[0].CSATScore,
          "CustomerComments": ""
        });
      }
      if (!this.linkTwo) {
        for (var i = 0; i < this.ratingArray.length; i++) {
          this.ratingArray[i].CustomerComments = this.feedback;
        }
        this.vendorService.submitTotalRatings(this.ratingArray, this.Token, this.caseNumber).subscribe((response: any) => {
          if (response.status == 'success') {
            const subButton = document.getElementById("subbutton") as HTMLInputElement;
            subButton.disabled = true;
            this.commentBox = false;
            this.showButton = false;
            this.successScreen = true;
            this.mainScreen = false;
          } else if (response.status == 'failure' && response.status ==
            'Invalid token') {
            const subButton = document.getElementById("subbutton") as HTMLInputElement;
            subButton.disabled = true;
            this.commentBox = false;
            this.showButton = false;
            this.successScreen = false;
            this.mainScreen = false;
            this.errorScreen = true;
          }
        })

      } else {
        this.vendorService.customerRatingTwo(this.ratingArray[0].CSATScore, this.serviceId,
          this.Token).subscribe((response: any) => {
            if (response.status == 'success') {
              const subButton = document.getElementById("subbutton") as HTMLInputElement;
              subButton.disabled = true;
              this.commentBox = false;
              this.showButton = false;
              this.successScreen = true;
              this.mainScreen = false;
            } else if (response.status == 'failure' && response.status ==
              'Invalid token') {
              const subButton = document.getElementById("subbutton") as HTMLInputElement;
              subButton.disabled = true;
              this.commentBox = false;
              this.showButton = false;
              this.successScreen = false;
              this.mainScreen = false;
              this.errorScreen = true;
            }
          })
      }
    } else {
      alert("Please select rating for all category");
    }
  }

}
