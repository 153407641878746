import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { VendorManagementService } from '../_services';

@Component({
  selector: 'ngx-droplocation',
  templateUrl: './droplocation.component.html',
  styleUrls: ['./droplocation.component.scss']
})
export class DroplocationComponent implements OnInit {

  loader: any = false;
  validUrl: any;
  vendorActiveServices: any;
  serviceFrom: any = true;
  serviceerrorMsg: any = false;
  serviceROSListShow: any = false;
  selectedROSServiceId: any;
  serviceROSList: any = [];
  selectedServiceStatus: any;
  serviceStatus: ["Reached Location", "Service Completed"];
  serviceTOWListShow: any = false;
  selectedTOWServiceId: any;
  serviceTOWList: any = [];
  mobileNumber: any;
  enteredMobileNumber: any;
  mobileerrorMsg: any = false;
  showClientName: any = false;
  enteredClientName: any;
  clientNameErr: any = false;
  otpVerify: any;
  enteredOtp: any;
  otperrorMsg: any = false;
  vendorId: any;
  serviceList: any;
  vendorForm: FormGroup;
  selectedServiceId: any;
  servicesAddlInfoRef: any;
  agentName: any;
  CaseNumber: any;
  agentID: any;
  ServiceType: any;
  selectedServiceData: any;
  positionlatitude: any;
  positionlongitude: any;
  showSpinner: boolean = true;
  SubmitbuttonCss: any = "display:none;";
  ServiceServiceIdList: any = [];
  isVideoPlayerShow: boolean;
  isSubmit: boolean;
  isSendOTPROS: boolean;
  isSelectService: boolean;
  isTrainingVideoId: boolean;


  constructor(private route: ActivatedRoute, private vendorService: VendorManagementService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.vendorForm = this.fb.group({
      activeRosService: [''],
      activeTowService: [''],
      updateStatus: [{ value: '', disabled: true}]
      // updateTowStatus: [''],

    })
    this.route.queryParams.subscribe(params => {
      this.vendorId = params.vendorId;
      if (!this.vendorId) {
        this.validUrl = true;
        // document.getElementById('selectService').style.display = 'none';
        this.isSelectService =false;
        return
      } else {
        let payLoad = {
          "vendorId": this.vendorId
        }
        this.serviceList = this.getActiveServicesOfVendor(payLoad);
        this.getLocation();
      }
    })

  }

  getActiveServicesOfVendor(payLoad) {
    this.loader = true;
    this.vendorService.getActiveServicesOfAVendor(payLoad).subscribe(res => {
      if (res.status == "success") {
        this.serviceList = res.message;
        let filterServiceList = [];
        this.serviceList.forEach(element => {
          if (!this.ServiceServiceIdList.includes(element?.ServiceId)) {
            if (element.ServiceType == 'RRP') {
              this.serviceROSList.push(element);
            } else if (element.ServiceType == 'TOW') {
              this.serviceTOWList.push(element);
            }

            filterServiceList.push(element);
            this.ServiceServiceIdList.push(element?.ServiceId)
          }
        });
        this.serviceList = filterServiceList
        if (this.serviceROSList.length > 0) {
          this.serviceROSListShow = true;
        }
        if (this.serviceTOWList.length > 0) {
          this.serviceTOWListShow = true;
        }
        if (this.serviceTOWList.length > 0) {
          this.serviceTOWListShow = true;
        }
        if (this.serviceTOWList.length > 0) {
          this.serviceROSListShow = true;
        }
        this.serviceFrom = true;
      } else {
        this.vendorActiveServices = true;
        // document.getElementById('selectService').style.display = 'none';
        this.isSelectService =false;
      }
    })

  }
  selectedServiceOnchange(type, event) {
    this.isSubmit =false;
    this.serviceerrorMsg = false;
    if (type == 'ROS') {
      this.selectedServiceId = event.target.value;
      this.selectedTOWServiceId = "";
      this.selectedROSServiceId = this.selectedServiceId;
      this.vendorForm.controls["activeTowService"].patchValue("");
    } else if (type == 'TOW') {
      this.selectedServiceId = event.target.value;;
      this.selectedTOWServiceId = this.selectedServiceId;
      this.selectedROSServiceId = "";
      this.vendorForm.controls["activeRosService"].patchValue("");
    }
    this.serviceList.forEach(element => {
      if (element.ServiceId == this.selectedServiceId) {
      
        this.isSubmit =true;
          // this.isSubmit =true;
        this.vendorForm.controls["updateStatus"].patchValue(element.ServiceStatus);
        if (element.ServiceStatus == 'Vendor Selected' || element.ServiceStatus == 'Vendor Started') {
          this.selectedServiceStatus = 'Reached Location';

          this.vendorForm.controls["updateStatus"].patchValue("Reached Location");
        } else if (element.ServiceStatus == 'Reached Location') {
          this.selectedServiceStatus = 'Service Completed';
          this.vendorForm.controls["updateStatus"].patchValue("Service Completed");
        }
        // document.getElementById('selectService').style.display = '';
        this.isSelectService =true;
        // document.getElementById('sendOTPROS').style.display = 'none';
        this.isSendOTPROS =false;
        if (this.selectedServiceStatus == "Service Completed" && type == 'ROS' && element.ServiceType == 'RRP') {
          document.getElementById('selectService').style.display = 'none';
          this.isSelectService =false;
          // document.getElementById('sendOTPROS').style.display = '';
          this.isSendOTPROS =true;
          this.enteredMobileNumber = element.ContactNumber;
        }
        //this.selectedServiceStatus = element.ServiceStatus;
        // document.getElementById('trainingVideoId').style.display = '';
        this.isTrainingVideoId = true;
      }
    });
    if (this.validate(this.selectedServiceId)) {
      this.isSubmit =true;
      this.selectedServiceStatus = "";
    }
  }

  selectedService(typeOfServiceStatus) {
    this.serviceerrorMsg = false;
    if (!this.validate(this.selectedServiceId)) {
      if (typeOfServiceStatus != "serviceCompletedROS") {
        this.serviceFrom = false;
        this.mobileNumber = true;
        this.enteredMobileNumber = "";
      }
      this.serviceList.forEach(element => {
        if (element.ServiceId == this.selectedServiceId) {
          this.servicesAddlInfoRef = element.servicesAddlInfoRef;
          this.agentName = element.AgentName;
          this.agentID = element.UserId;
          this.CaseNumber = element.CaseNumber;
          this.ServiceType = element.ServiceType;
          this.selectedServiceData = element;
          if (this.selectedServiceStatus == "Reached Location") {
            this.enteredMobileNumber = element.ContactNumber;
            this.serviceFrom = false;
            this.mobileNumber = false;
            this.otpVerify = true;
            // this.verifyPhoneNumber();
          }
          if (this.selectedServiceStatus == "Service Completed" && element.ServiceType == 'TOW') {
            this.showClientName = true;
          } else if (this.selectedServiceStatus == "Service Completed") {
            this.verifyPhoneNumber();
          }
        }
      });
      // if (typeOfServiceStatus == "serviceCompletedROS") {
      //   this.verifyPhoneNumber();
      // }
    } else {
      this.serviceerrorMsg = true;
    }

  }
  verifyPhoneNumber() {
    this.mobileerrorMsg = false;
    this.clientNameErr = false;
    if (!(/^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/).test(this.enteredMobileNumber)) {
      this.mobileerrorMsg = true;
    } else if (this.selectedServiceStatus == "Service Completed" && this.ServiceType == 'TOW' && this.validate(this.enteredClientName)) {
      this.clientNameErr = true;
    } else {
      var obj = {
        "mobileNumber": this.enteredMobileNumber,
        "createdBy": this.agentName,
        "serviceId": this.selectedServiceId,
        "caseNumber": this.CaseNumber,
        "serviceType": this.ServiceType,
        "serviceStatus": this.selectedServiceStatus,
        "schemeType": this.selectedServiceData.SchemeType
      }
      if (this.selectedServiceStatus == "Service Completed" && this.ServiceType == 'TOW') {
        obj["chasisNo"] = this.selectedServiceData.ChasisNo;
        obj["registrationNo"] = this.selectedServiceData.RegdNo;
        obj["vehModel"] = this.selectedServiceData.VehicleModel;
      }
      this.loader = true;
      this.vendorService.sendREDropOtp(obj).subscribe(res => {
        this.loader = false;
        if (res.status == 'success') {
          alert("Verification Code Sent Successfully");
          this.mobileNumber = false;
          this.serviceFrom = false;
          this.otpVerify = true;
          //this.agentID = res.user;
        } else {
          alert(res.message);
        }
      })
    }
  }

  verifyOtp() {

    this.otperrorMsg = false;
    if (!this.validate(this.enteredOtp)) {
      this.validateOtpFuction()
    } else {
      this.otperrorMsg = true;
    }
  }
  validate(value) {
    if (value == undefined || value === '' || value == null) {
      return true;
    } else {
      return false;
    }
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
    }
  }

  showPosition(position) {
    if (position.coords.latitude && position.coords.longitude) {
      sessionStorage.setItem("positionlatitude", position.coords.latitude)
      sessionStorage.setItem("positionlongitude", position.coords.longitude)
    }
  }

  validateOtpFuction() {
    this.positionlatitude = sessionStorage.getItem("positionlatitude");
    this.positionlongitude = sessionStorage.getItem("positionlongitude");
    sessionStorage.getItem(this.positionlongitude);
    if (this.positionlatitude && this.positionlongitude) {
      var obj = {
        "latitude": this.positionlatitude,
        "longitude": this.positionlongitude,
        "otp": this.enteredOtp,
        "servicesAddlInfoRef": this.servicesAddlInfoRef,
        "serviceId": this.selectedServiceId,
        "vendorStatus": this.selectedServiceStatus,
        "mobileNumber": this.enteredMobileNumber
      }
      if (this.selectedServiceStatus == "Service Completed" && this.ServiceType == 'TOW' && !this.validate(this.enteredClientName)) {
        obj["handoveredTo"] = this.enteredClientName
      }
      this.loader = true;
      this.vendorService.validateOtp(obj).subscribe(res => {
        this.loader = false;
        if (res.status == "success") {
          alert('Verification Code Verified Successfully');
          window.location.reload();
        } else {
          alert(res.message);
        }
      });

    }
  }
  showError(error) {
    console.log("error--" + error);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Your location setting has been turned off. Turn it on to share your location.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  }
  enterRecipientNumber(event) {
    this.enteredMobileNumber = event.target.value;
    this.validateMobileNumber(this.enteredMobileNumber);

  }

  validateMobileNumber(number: string): void {
    const mobileNumberPattern = /^[0-9]{10}$/;
    this.mobileerrorMsg = !mobileNumberPattern.test(number);
}
  enterRecipientName(event) {
    this.enteredClientName = event.target.value;
  }
  enterOtpFnction(event) {
    this.enteredOtp = event.target.value;
  }
  openTriningVideo() {
    this.isVideoPlayerShow = true
    document.getElementById('serviceFromId').style.display = 'none'
  }
  videoPlayerCloseFn() {
    this.isVideoPlayerShow = false;
    document.getElementById('serviceFromId').style.display = ''
  }

}
