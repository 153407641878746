import { NbMenuItem } from '@nebular/theme';

export const FINANCE_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Alerts',
    icon: 'bell-outline',
    children: [
      {
        title: 'Dispute Alerts',
        link: '/alerts/dispute'
      },
      {
        title: 'Registration Alerts',
        link: '/alerts/registration'
      },

      {
        title: 'GC-PPC/FTSP Registration Alerts',
        link: '/alerts/gc-ppc-registration'
      },

      {
        title: 'Existing Vendor GC-PPC Alerts',
        link: '/alerts/ex-gc-ppc-registration'
      }

    ]
  },
  {
    title: 'Cases/Service',
    icon: 'edit-2-outline',
    link: '/finance/case-service'
  },
  {
    title: 'Vendor',
    icon: 'person-outline',
    link: '/vendors/approved-vendors'
  },
  {
    title: 'Pending Vendors',
    icon: 'person-outline',
    link: '/vendors/pending-vendors'
  },

  {
    title: 'Payment & Invoice',
    icon: 'file-add',
    link: '/finance/payment-invoice'
  },

  {
    title: 'Invoices',
    icon: 'file-add',
    link: '/finance/invoice'
  },
  {
    title: 'Disputes',
    icon: 'file-add',
    link: '/finance/disputes'
  },

  // {
  //   title:'Vendor Reports',
  //   icon: 'file-add',
  //   link:'/finance/vendor-reports'  
  // },
  // {
  //   title:'All Cases',
  //   icon: 'file-add',
  //   link:'/alerts/all-cases'  
  // }

];
