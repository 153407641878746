<!-- <link rel="stylesheet"
href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
crossorigin="anonymous"> -->
<div class="mainPage">
    <div class="wht-bg">
        <div class="logo-bg">
            <a class="logo" href="#"><img class="img-responsive" src="assets/images/sfa.png" /></a>
        </div>

    </div>
    <div class="container-fluid">
        <div class="container">
            <div class="row login-1-page">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="logo-img mt-5">
                        <a class="logo" href="#"><img class="img-responsive"
                                src="assets/images/illustration_login.png" /></a>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="col-12 col-lg-12">
                        <div class="login-title">
                            <h4>Reset Password</h4>
                        </div>
                    </div>
                    <!-- <form [formGroup]="loginForm"> -->
                    <div class="col-12 col-lg-12 mt-50">
                        <div class="form-group30">
                            <label for="usr" class="labal-title">New Password:<span class="red">*</span></label>
                            <input type="password" aria-autocomplete="none" class="form-control inputs-frm"
                                [(ngModel)]="newPwd">
                        </div>
                        <div class="form-group30">
                            <label for="usr" class="labal-title">Confirm Password:<span class="red">*</span></label>
                            <input type="password" aria-autocomplete="none" class="form-control inputs-frm"
                                [(ngModel)]="cnfPwd">

                        </div>
                    </div>
                    <div class="col-12 col-lg-12">
                        <div class="mt-4">
                            <button type="button" class="btn login-btn" (click)="changePassword()">Submit</button>
                        </div>
                    </div>

                    <div class="login-title mt-50">
                        <dt><label>Notes</label></dt>
                        <dd>- Minimum 8 characters length</dd>
                        <dd>- Must have upper and lower case characters</dd>
                        <dd>- Use at least one number</dd>
                        <dd>- Special characters are optional</dd>
                    </div>

                </div>