import { NGXLogger } from "ngx-logger";
import { Injectable } from '@angular/core';
import { CommoncodesService } from '../_services/commoncodes.service';

@Injectable({ providedIn: 'root' })
export class DropDownLists {

    constructor(private logger: NGXLogger,private commonservices: CommoncodesService) { }

    // async getList(listName) {
    //     return new Promise((resolve) => {
    //         var jsonStr =  this.getList1(listName);
    //         resolve(jsonStr);
    //     });
    // }
    async getList(listName): Promise<string> {
        this.logger.log(listName);
        var jsonStr = "";
        try {
            let response = await fetch('assets/data/lists/' + listName + '.json');
            jsonStr = await response.json();
            this.logger.log(jsonStr);

        } catch (error) {
            this.logger.log(error)
        }
        return Promise.resolve(jsonStr);

    }
    async getDropDownListFromDb(codeType): Promise<string> {
        this.logger.log(codeType);
        var jsonStr: any;
        try {
            return new Promise((resolve) => {
                this.commonservices.getCodes(codeType).subscribe(res => {
                    if (res.status == 'success') {
                        jsonStr = res.message ? res.message.map(ele => { return ele.name }) : [];
                        resolve(jsonStr);
                    } else {
                        jsonStr = [];
                        resolve(jsonStr);
                    }
                }
                );
            });
        } catch (error) {
            this.logger.log(error)
        }
    }
}