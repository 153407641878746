<!-- <link rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
    crossorigin="anonymous"> -->

    <style>
        ::ng-deep body{
    background-image: url(../../assets/images/ea-loginbg.jpg) !important;  
    background-size: cover; 
}
    </style>    
<div class="mainPage">
    <div class="wht-bg">
        <div class="logo-bg">
            <a class="logo"><img class="img-responsive" src="assets/images/logo.png" /></a>
        </div>

    </div>
    <div class="container-fluid mweb-pad">
        <div class="container mweb-pad container-mweb">
            <div class="row login-1-page mt-0">
                <div class="col-lg-6 mw-45 d-none d-sm-block d-md-none d-lg-block">
                   
                </div>
                <div class="col-lg-5 mweb-pad mt-30">
                    <div class="col-12 col-lg-12">
                        <div class="login-title">
                            <h4>Sign in to RSA</h4>
                        </div>
                        <div class="login-title">
                            <p>
                                Enter your details below.
                            </p>
                        </div>

                    </div>
                    <form [formGroup]="loginForm">
                        <div class="col-12 col-lg-12 mt-10 mweb-mt-20">
                            <div class="form-group30">
                                <label for="usr" class="labal-title">Email/UserId/Phone:</label>
                                <input type="text" class="form-control inputs-frm" formControlName="username" id="usr"
                                    placeholder="Enter user name" autocomplete="off" aria-autocomplete="none">
                            </div>
                            <div class="form-group30">
                                <label for="pwd" class="labal-title">Password:</label>
                                <input type="password" class="form-control inputs-frm" formControlName="password"
                                    placeholder="Enter password" id="pwd" autocomplete="new-password"
                                    aria-autocomplete="none">
                            </div>
                        </div>
                        <div class="col-12 col-lg-12">
                            <div class="revti-post">
                                <div class="form-group30">
                                    <label for="pwd" class="labal-title">Enter
                                        OTP:</label>
                                    <input type="text" formControlName="otp" class="form-control inputs-frm otp-pad"
                                        placeholder="Enter OTP">
                                </div>
                                <div class="ablt-pos">
                                    <button type="button" (click)="getDataforFirstLogin()" class="btn otp-btn">Generate
                                        OTP</button>
                                </div>
                            </div>
                        </div>
                        <div class="row row-mrg-rl24 margin-field">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-7">

                                <input type="checkbox" id="termsandcondition" class="form-check-input"
                                    formControlName="checkAgree">
                                <label class="labal-title pl-l0">
                                    <span>I Agree to <a href="#" (click)="toggleModal()" style="color:#000"> Terms &
                                            Conditions</a></span>
                                </label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-5 mt-0 text-right textleft margin-field-left">
                                <a class="pas-title" (click)="openPasswordChangeDialog()">
                                    Forgot password?
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12">
                            <div class="mt-4">
                                <button type="button" class="btn login-btn" (click)="onSubmit()">Login</button>
                            </div>
                        </div>

                    </form>
                    <!-- <button type="button" class="btn login-btn" -->
                    <!-- (click)="openTermsAndConditions()">T & C</button> -->

                    <!-- <div class="form-group mb-5 frm-posti">
                        <div class="login-icons"><img class="img-responsive" src="assets/images/user-icon.png"/></div>
                        <input type="text" formControlName="username" placeholder="Username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                        </div>
                    </div>
                    <div class="form-group mb-5 frm-posti">
                        <div class="login-icons"><img class="img-responsive" src="assets/images/lock-icon.png"/></div>
                        <input type="password" formControlName="password" placeholder="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Enter OTP:</label>
                        <input type="text">
                        <button class="btn btn-primary">Generate OTP</button>
                    </div>
                    <div class="form-group text-right">
                        <a href="blank">Forgot Password</a>
                        <button [disabled]="loading" class="btn btn-login">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                       
                    </div> -->
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div #myDialog class="modal-content" *ngIf="showModal">
    <div class="modal-header modelheader">
        <h4 class="header-align">Terms And Conditions</h4><i class="fa fa-times close-icon cls-btn" (click)="close()"></i>

    </div>
    <div class="modal-body ">
        <div class="model-bdy">
            <strong>UNDERTAKING BY REGISTERED AUTHORISED SERVICE PROVIDER (“ASP”)</strong><br><br>

           <!--  I ASP of Europ Assistance India Private Limited (“the Company”), do hereby confirm, undertake and grant my
            consent to <br>
            <ol type="I">
                <li>enroll and register my/our Firm/ company on the e-portal offered by the Company for availing all
                    benefits offered under the Paperless Process</li>
                <li>upload and provide all mandatory documents for registration including but not limited to KYC, Id &
                    address proofs, registration certificates, licenses and other documents as required</li>
                <li>provide registered mobile number to receive SMS for all purposes including OTP and case updates
                </li>
                <li>complete the registration formalities with the external agencies under the said process, if
                    outsourced by the Company</li>
                <li> authorize Company to share my/our KYC/ Aadhar/ Pan/ Contact details with CDSL/ NSDL/ External
                    agencies for the purpose contemplated under this undertaking including but not limited to biometric
                    access, validations, receiving OTP and other incidental and ancillary activities.</li>
                <li>e-sign the agreement(s), addendum(s), terms & conditions and related documents thereof and further
                    authorize future amendments as and when need arises</li>
                <li>periodically review and provide authorizations on e-portal for activities relating to ASP</li>
                <li>authorize Company to generate and process invoice through Paperless initiative as per the protocols
                    set and receive payments via online channels (NEFT/ RGTS/ IMPS/ UPI)</li>
                <li>receive sms/ email statements only on registered contact no./ email id for all payment and case
                    related details</li>
                <li>to submit original receipts and physical bills to Company for verification in case of toll/ other
                    extra charges</li>
            </ol> -->


          <!--   <strong>Declaration:</strong>
            <div>
                <ol type="I">
                    <li>I/We have read and understood the contents for use of the Paperless Process and hereby agree by
                        the Terms & Conditions in its letter and spirit. If at anytime I/We do not agree to the present/
                        modified terms, I/ We should discontinue to avail the benefits.</li>
                    <li>I/We undertake to support the Company in enforcement of the Paperless initiative and to do the
                        needful </li>
                    <li>I/We confirm to not entice or encourage or engage or participate in any activity directly or
                        indirectly, which may lead or possible lead to breach/violation to terms of the said Process
                    </li>
                    <li>I/We will immediately report any breach or attempted breach to terms of the said Process.</li>
                    <li>I/We understand that the Process is subject to enhancements and agree to provide necessary
                        support at all times.</li>
                    <li>I/We hereby undertake that all information/documents provided by me/us are true/accurate/valid
                        and any inconsistency/inaccuracy/invalidity for the said details shall be solely my/our
                        responsibility and accordingly I/We agree to indemnify the Company for and against any loss,
                        injury or damages incurred by the Company as a result of such occurrence.</li>
                    <li>I/We understand that these terms control the relationship between Company and ASP and do not
                        create any third party beneficiary rights.</li>
                    <li>I/We hereby state that I/we have the right and authorization to enter into this arrangement on
                        behalf of my/our Firm/ Company.</li>
                </ol>
            </div> -->
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, eaque aperiam optio vel sunt libero tenetur inventore doloremque molestias magnam, recusandae, numquam deleniti obcaecati nam delectus quo eos labore. Laudantium.</p>
        </div>
    </div>

</div>

<!-- <div  class="model-cont-pad"> -->
<div *ngIf="firstLoginDialog" class="modal-content mfa-body">
    <div class="modal-header modelheader">
        Please update below details<i class="fa fa-times close-icon cls-btn" (click)="closeMFALogin()"></i>
    </div>
    <div class="modal-body">
        <div class="model-pdy">
            <div class="mt-50">
                <div class="error-message" *ngIf="firstLoginInfo.error">
                    Please fill the all mandatory fields
                </div>
                <div class="error-message" *ngIf="firstLoginInfo.emailError">
                    Please enter valid Email ID
                </div>
                <div class="error-message" *ngIf="firstLoginInfo.mobileError">
                    Please enter valid Mobile Number
                </div>
            </div>
            <div class="col-md-12  form-group">
                <label>Email ID:<span class='error-message'>*</span></label>
                <input type="text" class="form-control" [(ngModel)]="firstLoginInfo.email_id">
            </div>
            <div class="col-md-12 form-group">
                <label>Mobile Number:<span class='error-message'>*</span></label>
                <input type="text" class="form-control" [(ngModel)]="firstLoginInfo.mobileNumber">
            </div>
            <div class="col-md-12 text-right">
                <button class="btn login-btn portalbtnstyle btn-width" style="background-color: #0d6efd;"
                    (click)="updateDataforFirstLogin()">Submit</button>
            </div>

        </div>
    </div>
    <div style="margin-top: 4%;">

    </div>
</div>
<!-- </div> -->