import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VendorManagementService {
  private baseUrl = environment.apiUrl;

  private kycpopUpObj = {
    spinner: false,
    loading: false
  }

  private kycpopup = new BehaviorSubject(this.kycpopUpObj);
  kycPopupInfo = this.kycpopup.asObservable();

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  changeKycPopup(message: any) {
    this.kycpopup.next(message)
  }
  /*Approved Vendors*/
  /*Approved VendorsList*/
  VendorDetails() {
    return this.http.post<any>(`${this.baseUrl}api/vendor_v2/getAllActiveVendors`, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*******************/

  /*ASP Reports*/
  /*get RM ASP List*/
  getRMASPInfoTrack() {
    let url = `${this.baseUrl}api/rmDashboard/getRMASPInfoTrack`;
    return this.http.post<any>(url, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*get Region List*/
  getRegionList() {
    return this.http.post<any>(`${this.baseUrl}api/common/getRegionList`, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /*Region Wise List*/
  getRegionServiceCountReport() {
    let url = `${this.baseUrl}api/rmDashboard/getRegionServiceCountReport`;
    return this.http.post<any>(url, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getNHDetailedReport(idRegion, skipParam) {
    let url = `${this.baseUrl}api/rmDashboard/getNHDetailedReport`;
    return this.http.post<any>(url, { _idRegion: idRegion, skipParam: skipParam }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /***********/



  /*Pending Vendors */
  /*Pending Vendors List*/
  PendingVendorDetails(payload, uri) {
    return this.http.post<any>(`${this.baseUrl}` + uri, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /* Pending Vendor Based on user */
  getAllPendingVendorsBasedOnUser(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendor_v2/getAllPendingVendorsBasedOnUser`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  /* Deactive Vendor for RM'S Login  */

  DeactiveVendorDeatils(payload) {
    return this.http.post<any>(`${this.baseUrl}api/rmDashboard/getListOfDeactivatedActiveVendors`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /* Active Vendor for RM's Login */
  ActiveVendorDetails(payload) {
    return this.http.post<any>(`${this.baseUrl}api/rmDashboard/getListOfActiveVendors`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /* Active Vendor Based on user */
  ActiveVendorsBasedOnUser(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendor_v2/getActiveVendorsBasedOnUser`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /* Deactive Vendor Based on user */
  getDeActiveVendorsBasedOnUser(payload) {
    return this.http.post<any>(`${this.baseUrl}api/vendor_v2/getDeActiveVendorsBasedOnUser`, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /**************/

  progressWf(payLoad: object) {
    let url = `${this.baseUrl}api/vendor/progressWf`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  closeInformativeAlert(payLoad: object) {
    let url = `${this.baseUrl}api/vendor/closeInformativeAlert`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getCommentLog(payLoad: object) {
    let url = `${this.baseUrl}api/vendor/getCommentLog`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  diputeFileUpload(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/diputeFileUpload`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getKycOrVendorDocument(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/getKycOrVendorDocument`;
    return this.http.post<any>(url, payLoad, { responseType: 'blob' as 'json' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getVendorSingleDocument(payLoad: object) {
    let url = `${this.baseUrl}api/vendorDocuments/getVendorSingleDocument`;
    return this.http.post<any>(url, payLoad, { responseType: 'blob' as 'json' }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getVendorDocuments(payLoad: object) {
    let url = `${this.baseUrl}api/vendorDocuments/getVendorDocuments`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


  /*****Vendor Details ******/

  private vendorDetailspopup = new BehaviorSubject({
    spinner: false,
    loading: false,
    data: {
      Next: false,
      showPopup: false,
      totalData: [],
      index: 0
    }
  });
  vendorDeatilsInfo = this.vendorDetailspopup.asObservable();

  changeVendorsPopup(message: any) {
    this.vendorDetailspopup.next(message)
  }

  updateVendorDocumentFromDash(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/updateVendorDocumentFromDash`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  reUploadVendorDocuments(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/reUploadVendorDocuments`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  verifyOtp(payLoad: object) {
    let url = `${this.baseUrl}api/vendor-reg_v2/verifyOtp`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  updateVendorDetails(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/updateVendorDetails`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  sendOtpForEmailVerification(payLoad: object) {
    let url = `${this.baseUrl}api/vendor-reg_v2/sendOtpForEmailVerification`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  activatePendingVendor(payLoad: object) {
    let url = `${this.baseUrl}api/vendor/activatePendingVendor`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  activateOrInactivateDocument(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/activateOrInactivateDocument`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getAllStatesList(payLoad: object) {
    let url = `${this.baseUrl}api/vendor-reg_v2/getAllStatesList`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  updateTechnician(payLoad: object) {
    let url = `${this.baseUrl}api/rmDashboard/updateTechnician`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  updateVendorLocations(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/updateVendorLocations`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  activateInActivateService(payLoad: object) {
    let url = `${this.baseUrl}api/vendor_v2/activateInActivateService`;
    return this.http.post<any>(url, payLoad).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getExtDealers() {
    let url = `${this.baseUrl}api/vendor_v2/listDealerReferralApi`;
    return this.http.post<any>(url, '').pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getManagersData(clientNames) {
    // return this.http.get<any>(`${this.baseUrl}api/rmDashboard/getAllLMdata?clientName=`+clientName)
    return this.http.get<any>(`${this.baseUrl}api/rmDashboard/getAllLMdata?clientName=${clientNames}`).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  uploadManagersData(data, LoggedInUser) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('Loggedinuser', LoggedInUser);
    return this.http.post<any>(`${this.baseUrl}api/rmDashBoard/createOrUpdateLMinfo`, data, { headers }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getApprovedBorderAmount(serviceId) {
    return this.http.get<any>(`${this.baseUrl}api/cases/getApprovedBorderAmount?serviceId=${serviceId}`).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  getActiveServicesOfAVendor(vendorId: object) {
    let url = `${this.baseUrl}api/common/getActiveServicesOfAVendor`;
    return this.http.post<any>(url, vendorId).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  sendREDropOtp(payload) {
    let url = `${this.baseUrl}api/common/sendREDropOtp`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }
  validateOtp(payload) {
    let url = `${this.baseUrl}api/common/validateOtp`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  ///feedback routes
  customerRatingTwo = function (rating: any, serviceId: any, Token: any) {
    var data = {
      "csatScoreValue": rating,
      "serviceId": serviceId,
      "token": Token
    }
    var url = `${this.baseUrl}api/csat/updateCsatRatingInService`;
    return this.http.post(url, data);
  }
  validateUrl = function (caseNumber: any, Token: any) {
    var data = {
      "caseNumber": caseNumber,
      "token": Token
    }
    var url = `${this.baseUrl}api/vendor-reg_v2/validatePasswordToken`;
    return this.http.post(url, data);
  }
  getSchemeQuestions = function (schemeType: any, caseNumber: any) {
    var data = {
      "schemeType": schemeType,
      "caseNumber": caseNumber
    }
    var url = `${this.baseUrl}api/csat/getCsatQstns`;
    return this.http.post(url, data);
  }
  submitTotalRatings = function (arr: any, token: any, caseid: any) {
    var data = {
      "csatScoresArr": arr,
      "token": token,
      "caseId": caseid
    }
    var url = `${this.baseUrl}api/csat/updateCsatRating`;
    return this.http.post(url, data);
  }

}

