import { NbMenuItem } from '@nebular/theme';

export const SV_MENU_ITEMS: NbMenuItem[] = [

  {
    title:'Open Cases',
    icon: 'bell-outline',
    link:'/supervisor/open-cases'
  },
  // {
  //   title:'Activation Timer',
  //   icon: 'bell-outline',
  //   link:'/supervisor/activation-timer'
  // } ,
  // {
  //   title:'Timers Count',
  //   icon: 'bell-outline',
  //   link:'/supervisor/timers-count'
  // },
  // {
  //   title:'Timer Denials',
  //   icon: 'bell-outline',
  //   link:'/supervisor/timer-denails'
  // },
  // {
  //   title:'Timer Details',
  //   icon: 'bell-outline',
  //   link:'/supervisor/timer-details'
  // },
  {
    title: 'Events',
    icon: 'file-add',
    link: '/supervisor/events'
  },
  {
    title:'Block Customers',
    icon: 'bell-outline',
    link:'/supervisor/block-customers'
  },
  {
    title: 'Reports',
    icon: 'file-add',
    link: '/supervisor/reports'
  },
  {
    title: 'Case Booking',
    icon: 'edit-2-outline',
    link: '/supervisor/case-booking'   
  } 

];
