  <div class="container" #myDialog *ngIf="LoginData">
    <nb-card class="w-100">
      <nb-card-header> <h4>Change Password</h4></nb-card-header>
      <nb-card-body class="scroll">
      <form>
        <div class="form-group">
          <label for="currentPassword">Current Password:<span class="star">*</span></label>
          <input class="form-control" type="password" id="currentPassword" [(ngModel)]="chgPassword.currentPswd" name="currentPassword" required>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password:<span class="star">*</span></label>
          <input class="form-control" type="password" id="newPassword" [(ngModel)]="chgPassword.newPswd" name="newPassword" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password:<span class="star">*</span></label>
          <input class="form-control" type="password" id="confirmPassword" [(ngModel)]="chgPassword.confirmPswd" name="confirmPassword" required>
        </div>
        <div *ngIf="errMsg" class="error-message" style="color: red">
          Please fill all fields.
        </div>
        <!-- <div *ngIf="mismatch" class="error-message">
          Confirm password must match with new password
        </div> -->
        <button (click)="ChangePassword()" nbButton status="primary" class="btn save-btn btn-primary">Change</button>
        <br>
        <br>
        <span style="color: red;">Note:</span><br> - Minimum 8 characters length<br> - Must have upper and lower case characters <br> - Use at least one number <br> - Special characters are optional
      </form>
  
    </nb-card-body>
      
    </nb-card>
    </div>