import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NotificationService } from './index';
import { Router } from '@angular/router';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private notification: NotificationService, private Router: Router) { }
  //For Excel File
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    var currDate = new Date().toLocaleString();
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + currDate + EXCEL_EXTENSION);
  }

  downloadInvoice(filePath, fileName) {
    let url = `${this.baseUrl}api/vendor_v2/downloadInvoice`
    // return this.http.post(url, { fileName: filePath }, { responseType: 'arraybuffer' })
    return this.http.post(url, { fileName: filePath }, { responseType: 'arraybuffer' }).pipe(
      map((res) => {
        this.generatePdf(res, fileName)
        return true;
      })
    );
  }


  generatePdf(buffer: any, fileName: string) {
    let decodedString = String.fromCharCode.apply(null, new Uint8Array(buffer));
    let obj: any;
    /**check if json is array or error**/
    this.validateJSON(decodedString) ? (obj = JSON.parse(decodedString)) : (obj = '')

    /**if file doesnot exist**/
    if (obj.status == 'failure') {
      // this.notification.Info("File doesn't exist");
      alert("File doesn't exist")
    } else {
      const blob = new Blob([buffer], { type: 'application/pdf' });
      var pdfName = fileName + '.pdf';
      FileSaver.saveAs(blob, pdfName);
    }
  }
  validateJSON(data) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;

  }

  // viewService(data) {
  //   if (data.status == 'Draft') {
  //     this.Router.navigate(['/cases/create-case'], { queryParams: { id: data.case_id,service_id: data.service_id }, skipLocationChange: false })
  //   } else {
  //     this.Router.navigate(['/cases/edit-case'], { queryParams: { id: data.case_id,service_id: data.service_id }, skipLocationChange: false })
  //   }
  // }
  viewService(data) {
    if (data.status == 'Draft') {
      this.Router.navigate(['/cases/create-case'], { queryParams: { id: data.case_id, serviceId: data.serviceId, status: 1 }, skipLocationChange: false })
    } else {
      this.Router.navigate(['/cases/edit-case'], { queryParams: { id: data.case_id, serviceId: data.serviceId, status: data.status, from: 'viewService' }, skipLocationChange: false })
    }
  }
  ///
  validateExcelFormat(filename: string) {
    var ext = filename.substring(filename.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'csv' || ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx') {
      return true;
    }
    else {
      return false;
    }
  }

  prevNext(method, API, payLoad) {
    if (method == 'get') {
      return this.http.get<any>(`${this.baseUrl}${API}`, payLoad);
    } else if (method == 'post') {
      return this.http.post<any>(`${this.baseUrl}${API}`, payLoad);
    }
  }
}
