import { Component, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { first } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { DOCUMENT } from '@angular/common';
import { USER_ROLES } from '../_helpers/Constants';
import { NbWindowService } from '@nebular/theme';
//import { ChangePasswordComponent } from '../shared/change-password/change-password.component'; import { TermAndConditionComponent } from '../term-and-condition/term-and-condition.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { NbDialogService } from '@nebular/theme';
import { PwdChangeComponent } from '../pwd-change/pwd-change.component'; // update this path

// import { MatDialog } from '@angular/material/dialog';
import { Context } from 'html2canvas/dist/types/core/context';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public windowRef: any;
  returnUrl: string;
  loading = false;
  submitted = false;
  loginOtp: any;
  emailId: any;
  termsAndCondition: boolean = false;
  @ViewChild('tcdialog') tcdialog: TemplateRef<any>;
  @ViewChild('myDialog') myDialog!: ElementRef;
  showModal: boolean = false;
  generateOtpFlag: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private widowsService: NbWindowService,
    private router: Router,
    private windowService: NbWindowService,
    private authenticationService: AuthenticationService,
    private dialogService: NbDialogService,
    private renderer: Renderer2,

    // public matDialog: MatDialog,
    @Inject(DOCUMENT) private document: any) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //     this.router.navigate(['/']);
    // }
    let logininfo = JSON.parse(sessionStorage.getItem('currentUser'))
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (logininfo) {
          this.authenticationService.logout();
        }
      }
    });


  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      checkAgree: ['', Validators.required],
      otp: ['', Validators.required]
    });


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (environment.ssoEnable) {
      this.document.location.href = environment.ssoLoginUrl;
    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }


  onSubmit() {

    if (!this.f.password.value || !this.f.username.value) {
      alert("Enter  username or password")
      return;
    }
    // stop here if form is invalid

    if (this.f.otp.value == undefined || !this.f.otp.value) {
      alert("Please enter OTP")
      return;
    }
    if (!this.generateOtpFlag) {
      alert("Please Click on Generate OTP")
      this.loginForm.get("otp").setValue("")
      return;
    }
    if (!this.f.checkAgree.value) {
      alert("Please agree to terms and conditions.")
      return;
    }

    if (this.loginForm.invalid) {
      return;
    }


    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.f.otp.value, this.emailId)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data.role);
          if (data.status && data.status == "success") {
            if (data.role == "admin") {
              window.location.href = "/admin/users";
              // this.router.navigate(['/admin']);
            } else if (data.role == "network head") {
              window.location.href = "/alerts/registration";
              //this.router.navigate(['/cases']);
            } else if (data.role == "regional manager") {
              window.location.href = "/alerts/registration";
              //this.router.navigate(['/cases']);
            } else if (data.role == "agent") {
              window.location.href = "/alerts/agent";
            } else if (data.role == "finance team") {
              window.location.href = "/finance/case-service";
            } else if (data.role == "legal team") {
              window.location.href = "/alerts/registration";
            } else if (data.role == "supervisor") {
              window.location.href = "/supervisor/open-cases";
            } else if (data.role == USER_ROLES.TERRITORY_ROLE) {
              window.location.href = "/rm/active-vendors";
            } else if (data.role == USER_ROLES.ZONAL_ROLE) {
              window.location.href = "/rm/active-vendors";
            } else {
              alert("User Not Found, Please try with valid login details.")
              window.location.href = "/login";
            }
          } else {
            this.loading = false;
            alert(data.message);
          }

        },
        error => {
          this.loading = false;
        });
  }
  firstLoginDialog: boolean;
  getLoginOtp() {
    // return;
    let payload = {
      userName: this.loginForm.value.username,
      password: this.loginForm.value.password
    }
    this.authenticationService.getLoginOtp(payload).subscribe(res => {
      console.log(res)
      if (res.status == 'Success') {
        this.loginOtp = res.OTP;
        this.generateOtpFlag = true;
      } else {
        alert("UserName OR Password is wrong")
      }

    })
  }
  getDataforFirstLogin() {
    if (this.loginForm.value.password && this.loginForm.value.username) {
      let payload = {
        userName: this.loginForm.value.username,
        password: this.loginForm.value.password
      }
      this.authenticationService.getDataforFirstLogin(payload).subscribe(res => {

        if (res.status == 'success' && res.message) {
          this.emailId = res.message.email_id;
          if (!res.message.profileUpdated) {
            this.firstLoginDialog = true;
            this.firstLoginInfo.mobileNumber = res.message.mobileNumber;
            this.firstLoginInfo.email_id = res.message.email_id;
          } else {
            alert("Otp sent to your registered Mobile Number " + res.message.mobileNumber)
            this.getLoginOtp();
          }
        } else {
          alert(res.message);
        }
      })
    } else {
      alert("Please Enter User name and Password")
    }

  }

  // showTermandCondition :boolean=false;
  // openTermsAndConditions() {
  //   this.showTermandCondition=true;
  //   location.reload();
  // }

  openPasswordChangeDialog() {
    // this.dialogService.open(PwdChangeComponent);
    window.location.href = '/forgot-password';
  }

  close() {
    this.showModal = false;
  }
  closeMFALogin() {
    this.firstLoginDialog = false;
  }
  toggleModal() {
    this.showModal = !this.showModal;
    const modalElement = this.myDialog.nativeElement;
    if (this.showModal) {
      this.renderer.setStyle(modalElement, 'display', 'block');
    } else {
      this.renderer.setStyle(modalElement, 'display', 'none');
    }
  }
  firstLoginInfo = {
    email_id: '',
    mobileNumber: '',
    error: false,
    emailError: false,
    mobileError: false
  }
  updateDataforFirstLogin() {
    this.firstLoginInfo.error = false;
    this.firstLoginInfo.emailError = false;
    this.firstLoginInfo.mobileError = false;
    if (!this.firstLoginInfo.email_id || !this.firstLoginInfo.mobileNumber) {
      this.firstLoginInfo.error = true;
    }

    if (this.firstLoginInfo.email_id) {
      if (!this.verifyDetails('email', this.firstLoginInfo.email_id)) {
        this.firstLoginInfo.emailError = true;
      }
    }

    if (this.firstLoginInfo.mobileNumber) {
      if (!this.verifyDetails('mobileNo', this.firstLoginInfo.mobileNumber)) {
        this.firstLoginInfo.mobileError = true;
      }
    }

    if (!this.firstLoginInfo.error && !this.firstLoginInfo.emailError && !this.firstLoginInfo.mobileError) {
      let payload = {
        userName: this.loginForm.value.username,
        password: this.loginForm.value.password,
        mobileNumber: this.firstLoginInfo.mobileNumber,
        email_id: this.firstLoginInfo.email_id
      }
      this.authenticationService.updateDataforFirstLogin(payload)
        .subscribe(res => {
          if (res.status == 'success') {
            alert('Otp sent to your registered Mobile Number ' + this.firstLoginInfo.mobileNumber);
            this.firstLoginDialog = false;
            // You'll need to replace the jQuery code with Angular code
          } else {
            alert('Unable to update the Details.. Please try again..');
          }
          // You'll need to replace the $route.reload() with Angular code
        });
    }
  }

  verifyDetails(type: string, input: string): boolean {
    if (type === 'email' && input) {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailPattern.test(input)) {
        return false;
      }
    } else if (type === 'mobileNo' && input) {
      const digits = /[^\d]/g;
      const pattern = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
      if (digits.test(input)) {
        return false;
      }
      if (!pattern.test(input)) {
        return false;
      }
    }
    return true;
  }
}







