import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Developed by @ <b>Softforce Apps</b>  2024, Version: 2.2.0
    </span>`,
})
export class FooterComponent {
  year: number = new Date().getFullYear()
}
