import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  private baseUrl = environment.apiUrl;

  private popUpObj = {
    loading: false,
    clients: [],
    agentArray: []
  }
  private filter = new BehaviorSubject(this.popUpObj);
  filterPopup = this.filter.asObservable();

  constructor(private http: HttpClient) { }

  changeFilterPopup(message: any) {
    this.filter.next(message)
  }

  /***Client Agent List ****/
  getClientAndAgentDetailsForSuperVisor(payload: object) {
    let url = `${this.baseUrl}api/supervisor/getClientAndAgentDetailsForSuperVisor`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /****Open Cases *********/
  getSupervisorOpenCasesAndServices(payload: object) {
    let url = `${this.baseUrl}api/supervisor/getSupervisorOpenCasesAndServices`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getCasesBasedOnTimeLap(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCasesBasedOnTimeLap`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  /*****Activation Timer ****/
  getCountOfServicesBasedOnDenials(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCountOfServicesBasedOnDenials`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getServicesBasedOnDenialsAndTimeLap(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getServicesBasedOnDenialsAndTimeLap`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


  /*****Timers Count ****/
  getCountOfServicesBasedOnTimer(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCountOfServicesBasedOnTimer`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getCasesAndServicesBasedOnTimer(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCasesAndServicesBasedOnTimer`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


  /*****Timer Denails */
  getCountOfServicesBasedOnDenielsAndVendorstatus(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCountOfServicesBasedOnDenielsAndVendorstatus`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getCasesAndServicesBasedOnTimerAndDenials(payload: Object) {
    let url = `${this.baseUrl}api/supervisor/getCasesAndServicesBasedOnTimerAndDenials`;
    return this.http.post<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }

  getInActiveCustomers(payload: Object) {
    let url = `${this.baseUrl}api/customer/getInActiveCustomers`;
    return this.http.get<any>(url, payload).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A network error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(`Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        alert(error)
        return throwError(
          error);
      })
    );
  }


}
