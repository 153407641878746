import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService,NbIconConfig } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: NbToastrService) { }

  positions = NbGlobalPhysicalPosition;
  Info(message) {
    status = 'primary'
    this.toastrService.show("", message, {status});
  }
  Warning(message) {
    alert(message);
  }
  Success(message) {
    status = 'success'
    this.toastrService.show("",message, { status});
  }
  Error(message) {
    status = 'danger'

    this.toastrService.show("",message, { status });

  }

}
