import { NbMenuItem } from '@nebular/theme';

export const ZM_MENU_ITEMS: NbMenuItem[] = [

  {
    title:'Active Vendors',
    icon: 'person-done-outline',
    link:'/rm/active-vendors'  
},
{
  title:'DeActive Vendors',
  icon: 'person-done-outline',
  link:'/rm/inactive-vendors'  
},
{
  title:'Pending Vendors',
  icon: 'alert-circle-outline',
  link: '/vendors/pending-vendors'  
},
{
  title:'Completed Services',
  icon: 'checkmark-circle-outline',
  link:'/cases/services/completed-services-search'   
},
// {
//   title:'Completed Cases',
//   icon: 'map-outline',
//   link:'/networkhead/approvedVendors'  
// },
{
  title:'Invoices',
  icon: 'file-add',
  link:'/rm/invoices'  
}

];
