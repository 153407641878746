export const USER_ROLES =
{
  FINANCE_ROLE: "finance team",
  NETWORK_HEAD_ROLE: "network head",
  RM_ROLE: "regional manager",
  LEGAL_ROLE: "legal team",
  ADMIN_ROLE: "admin",
  AGENT_ROLE: "agent",
  SUPERVISOR_ROLE: "supervisor",
  EXTERNAL_USER_ROLE: "external_user_role",
  IT_TEAM_ROLE: "it team",
  VMS_TEAM_ROLE: "vms team",
  ZONAL_ROLE: "zonal manager",
  TERRITORY_ROLE: "territory manager"
}

export interface TreeNode {
  id: number;
  name: string;
  children?: TreeNode[];
}

export const SERVICE_STATUS = {
  VENDOR_SELECTED: 1,
  VENDOR_STARTED: 2,
  REACHED_LOCATION: 3,
  SERVICE_COMPLETED: 4,
  SERVICE_CANCELLED: 5,
  SERVICE_FAILED: 6,
  VENDOR_NOT_ASSIGNED: 7,
  WAITING_FOR_PAYMENT: 8,
  WAITING_FOR_ADDL_PAYMENT: 9,
  CANCELLED_ERROR_OR_TRAIL: 10,
  VENDOR_INFO_SHARED_IN_ELGIBLE_CUSTOMER: 11,
}

export const GOOGLE_API_TYPES = {
  Map : 'Maps API',
  Marker : 'Marker',
  LatLng : 'LatLng',
  Geocoder: 'Geocoding',
  DirectionsRenderer: 'Directions API',
  DirectionsService: 'Directions API',
  Autocomplete: 'Autocomplete API',
  InfoWindow: 'InfoWindow'
}