import { NbMenuItem } from '@nebular/theme';

export const NH_MENU_ITEMS: NbMenuItem[] = [


  {
    title: 'Alerts',
    icon: 'bell-outline',
    children: [
      {
        title: 'Registration Alerts',
        link: '/alerts/registration'
      },
      {
        title: 'GC-PPC/FTSP Registration Alerts',
        link: '/alerts/gc-ppc-registration'
      },

      {
        title: 'Existing Vendor GC-PPC Alerts',
        link: '/alerts/ex-gc-ppc-registration'
      },

      {
        title: 'Existing Vendor Active-Inactive Alerts',
        link: '/alerts/vendor-active-inactive-alerts'
      },

      {
        title: 'Dispute Alerts',
        link: '/alerts/dispute'
      },
      {
        title: 'Existing Vendor Location Alerts',
        link: '/alerts/vendor-location-alerts'
      },
      {
        title: 'Existing Location Active-InActive Alerts',
        link: '/alerts/location-active-inactive-alerts'
      },
      {
        title: 'Existing Service Rates Alerts',
        link: '/alerts/service-rates'
      },
      {
        title: 'Existing Services Active-Deactive Alerts',
        link: '/alerts/service-active-deactive-alerts'
      },
      {
        title: 'Location Contact Detail Updation',
        link: '/alerts/location-contact'
      }

    ]
  },
  {
    title: 'Approved Vendors',
    icon: 'person-done-outline',
    link: '/vendors/approved-vendors'
  },
  {
    title: 'Pending Vendors',
    icon: 'alert-circle-outline',
    link: '/vendors/pending-vendors'
  },
  {
    title: 'Completed Services',
    icon: 'checkmark-circle-outline',
    link: '/cases/services/completed-services'
  },
  {
    title: 'ASP Counts',
    icon: 'bar-chart',
    link: '/vendors/asp-reports'
  },
  {
    title: 'Regions',
    icon: 'map-outline',
    link: '/admin/regions'
  },
  {
    title: 'Invoices',
    icon: 'file-add',
    link: '/finance/invoice-search'
  },
  {
    title: 'Events',
    icon: 'file-add',
    link: '/finance/events'
  },
  {
    title: 'Reports',
    icon: 'file-add',
    link: '/admin/reports'
  },
  {
    title: 'All Cases',
    icon: 'file-add',
    link: '/alerts/all-cases'
  },
  {
    title: 'Vendor Reports',
    icon: 'file-add',
    link: '/finance/vendor-reports'
  },
  {
    title:'MSIL Change',
    icon: 'file-add',
    link:'/alerts/msilchange'
  },
  {
    title: 'Manager Information',
    icon: 'file-add',
    link: '/alerts/managerInfo'
  }


];
