import { NbMenuItem } from '@nebular/theme';

export const LT_MENU_ITEMS: NbMenuItem[] = [

 
  {
    title:'Alerts',
    icon: 'bell-outline',
    link:'/alerts/user-alerts',
    children:[
      {
      title:'Registration Alerts',
      link:'/alerts/registration'
    },
    {
      title:'GC-PPC/FTSP Registration Alerts',
      link:'/alerts/gc-ppc-registration'
    }
    ]
  } 
];
