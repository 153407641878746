import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, from } from "rxjs";

import { AuthenticationService } from "../_services";
import { delay, takeUntil } from "rxjs/operators";
import * as moment from "moment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  loading: boolean;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUser = this.authenticationService.currentUserValue;
    let headerObj = {
      Authorization: "",
      Token: "",
    };
    if (currentUser && currentUser.token) {
      if (this.authenticationService.isTokenExpired(currentUser.token)) {
        // alert('Your session has expired. Please log in again.');
        this.authenticationService.logout();
        return throwError("Your session has expired. Please log in again");
      } else {
        let tokenExpiration = moment
          .unix(this.authenticationService.getExpirationTime(currentUser.token))
          .diff(moment(), "minutes");
        if (tokenExpiration <= 5) {
          this.authenticationService
            .refreshUserData(currentUser.token)
            .pipe(delay(2000))
            .subscribe((users: any) => {
              headerObj.Authorization = users.token;
            });
        }
        headerObj.Authorization = currentUser.token;
      }
    }
    request = request.clone({ setHeaders: headerObj });
    return next.handle(request);
  }
}
