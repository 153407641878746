import { NbMenuItem } from '@nebular/theme';

export const ADMIN_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'User Management',
    icon: 'person-outline',
    link: '/admin/users'
  },
  {
    title: 'Region Management',
    icon: 'map-outline',
    link: '/admin/regions'
  },
  {
    title: 'Common Codes',
    icon: 'settings-outline',
    link: '/admin/common-codes'
  },
  {
    title: 'DRSA LT Clients',
    icon: 'people-outline',
    link: '/admin/drsa-lt-clients'
  },
  {
    title: 'Reports',
    icon: 'edit-2-outline',
    link: '/admin/reports'
  },
  {
    title: 'IRSA Reports',
    icon: 'edit-2-outline',
    link: '/admin/irsaReports'
  },
  {
    title: 'Diagnostics',
    icon: 'person-outline',
    link: '/admin/view-diagnostics'
  },
  {
    title: 'Workflow',
    icon: 'settings-outline',
    link: '/admin/work-flow'
  },
  {
    title: 'Clients',
    icon: 'person',
    // link: '/admin/client'
    link: '/admin/client-maintenance'
  },
  // {
  //   title: 'Micro Site',
  //   icon: 'settings',
  //   link: '/admin/microsite'   
  // },
  // {
  //   title: 'Customers',
  //   icon: 'person-outline',
  //   link: '/cases/customers/lists'   
  // },



];
