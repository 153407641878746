import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from "../../environments/environment";
import { AuthenticationService } from '../_services';

@Component({
  selector: 'ngx-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  loading = false;

  constructor(private route: ActivatedRoute,
    private authService: AuthenticationService) { }

  ngOnInit(): void {

    if (environment.ssoEnable) {
      this.loading = true;
      if (this.route.queryParams) {
        this.route.queryParams.subscribe(params => {
          this.authService.getJwt(params).subscribe((data: any) => {
            if (data.role == "admin") {
              window.location.href = "/admin/regions";
              // this.router.navigate(['/admin']);
            } else if (data.role == "network head") {
              window.location.href = "/alerts/registration";
              //this.router.navigate(['/cases']);
            } else if (data.role == "regional manager") {
              window.location.href = "/alerts/registration";
              //this.router.navigate(['/cases']);
            } else if (data.role == "agent") {
              window.location.href = "/alerts/agent";
            } else if (data.role == "finance team") {
              window.location.href = "/alerts/dispute";
            } else if (data.role == "legal team") {
              window.location.href = "/alerts/registration";
            } else if (data.role == "supervisor") {
              window.location.href = "/supervisor/open-cases";
            }else if (data.role == "zonal manager") {
              window.location.href = "/rm/active-vendors";
            }else if (data.role == "territory manager") {
              window.location.href = "/rm/active-vendors";
            }

          })
          console.log(params);
        })
      }


    }


  }

}
