import { Component, OnInit } from '@angular/core';
import { CommoncodesService } from '../_services/commoncodes.service';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  userId: any;
  constructor(private commonService: CommoncodesService,) { }

  ngOnInit(): void {
  }
  forgotPassWord() {
    // this.userId = document.getElementById("usr")
    if (!this.userId) {
      alert("Please enter username.")
      return;
    }
    console.log(this.userId)
    let payload = {
      path: window.location.origin + "/reset-password",
      userId: this.userId
    }
    this.commonService.forgotPassWord(payload).subscribe(res => {
      if (res.status == "success") {
        alert(res.message)
        window.location.href = "/login";
      } else if (res.status == "failure") {
        alert(res.message)
      } else {
        alert("Password updating failed")
      }
    })
  }
}
