import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CaseBookingService } from '../_services';
import { SERVICE_STATUS } from './Constants';
import { DropDownLists } from './dropDown.lists';

@Injectable({ providedIn: 'root' })
export class ServiceUtils {
    north21Cities = [];
    pwDrsaCities = [];
    serviceStatusList: any;
    clientDefaults: any;
    constructor(
        private caseService: CaseBookingService,
        private dropDownLists: DropDownLists
    ) { }

    get21NcitiesList() {
        return this.caseService.getDrsaCities().subscribe((res) => {
            if (res.status == 'success') {
                this.north21Cities = res.message.drsaCities;
                this.pwDrsaCities = res.message.pwdrsaCities
                console.log("DRSA cities Loaded");
            }
        });

    }
    async getClientDefaults() {
        if (!this.clientDefaults) {
            let resp = await fetch('assets/data/clientDefaults.json');
            this.clientDefaults = await resp.json();
        }
        return this.clientDefaults;
    }

    async loadServiceStatusList() {

        if (!this.serviceStatusList) {
            let listData = await this.dropDownLists.getList("VendorStatus");
            console.log("status list loaded", listData);
            this.serviceStatusList = listData;
        }

    }

    loadDRSACities(): Subscription {
        return this.get21NcitiesList();
    }

    isTimeInDRSAHours(): boolean {
        let date = new Date();
        var h = date.getHours();
        return (h >= 8 && h <= 19);
    }

    checkDRSAAvailability(city: string, isDrsa: boolean): string {
        let citiesList = isDrsa ? this.north21Cities : this.pwDrsaCities;
        let cityIndex = citiesList.findIndex(cityObj => {
            if (cityObj.name.toUpperCase() == city.toUpperCase()) {
                return true;
            }
            return false;
        });

        //if (cityIndex != -1 && this.isTimeBetween(timeObje)) {
        if (cityIndex != -1 && this.isTimeInDRSAHours()) {
            return citiesList[cityIndex].code;
        } else {
            return "";
        }
    }

    getStatusConstant(status: string): number {
        console.log("status to be converted to id", status);
        switch (status) {
            case "vendorNotAssigned":
                return SERVICE_STATUS.VENDOR_NOT_ASSIGNED;
            case "Vendor Selected":
                return SERVICE_STATUS.VENDOR_SELECTED;
            case "Vendor Started":
                return SERVICE_STATUS.VENDOR_STARTED;
            case "Reached Location":
                return SERVICE_STATUS.REACHED_LOCATION;
            case "Service Completed":
                return SERVICE_STATUS.SERVICE_COMPLETED;
            case "Service Cancelled":
                return SERVICE_STATUS.SERVICE_CANCELLED;
            case "Service Failed":
                return SERVICE_STATUS.SERVICE_FAILED;
            case "waitingForPayment":
                return SERVICE_STATUS.WAITING_FOR_PAYMENT;
            case "waitingForAddlPayment":
                return SERVICE_STATUS.WAITING_FOR_ADDL_PAYMENT;
            case "Cancelled-error or trail":
                return SERVICE_STATUS.CANCELLED_ERROR_OR_TRAIL;
            case "vendorInfoShared-inElgibleCustomer":
                return SERVICE_STATUS.VENDOR_INFO_SHARED_IN_ELGIBLE_CUSTOMER;
            default:
                return -1;
        }

    }

    getStatusObject(statusId: number): any {
        let s = this.serviceStatusList.find(x => x.id == statusId);
        return s;
    }

    getStatusValue(statusId: number): any {
        // console.log(this.serviceStatusList, statusId)
        let s = this.serviceStatusList.find(x => x.id == statusId);
        console.log("status value", s.value);
        return s.value;
    }
}

