import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CompletedservicesService {

  private url =`${environment.apiUrl}api`;
  constructor(private http:HttpClient) { }
//viewmap
viewMap(claimId,serviceId){
  return this.http.post<any>(`${this.url}/vendorSearch_v2/getRouteMapImage`,{claimId:claimId,serviceId:serviceId}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
//auditlog
auditlog(caseNumber){
  return this.http.post<any>(`${this.url}/common/getHistoryOfACase`,{caseNumber:caseNumber}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
//getcodes diagnosctics  breakdownreason
getdiagnostics(){
  let url=`${this.url}/dependentList/getcodes`
  return this.http.post<any>(url,{"providerName":"DecisionMatrixFW","dependentType":"DecisionMatrix"}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
//diagnosctics getsubcodes breakdowncause
getbreakdowncause(breakdownreason){
  let url=`${this.url}/dependentList/getSubcodes`
  return this.http.post<any>(url,{providerName: "RoyalEnfield", "dependentType": "DecisionMatrix", "name": breakdownreason}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
//diagnoscitcs symptoms
getsymptoms(breakdownreason,symptoms){
  let url=`${this.url}/dependentList/getSubChilds`
  return this.http.post<any>(url,{providerName: "RoyalEnfield", "dependentType": "DecisionMatrix", "name": breakdownreason,subCode:symptoms}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
//customer usage alert
getcustomeralert(customerRef){
  let url=`${this.url}/customer/customerUsageAlert`
  return this.http.post<any>(url,{customerRef:customerRef}).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
// //get codes for customer
// getcustomer(){
//   let url=`${this.url}/dependentList/getcodes`
//   return this.http.post<any>(url,{"providerName": "EAI", "dependentType": "events"});
// }
getCompletedAlertsOnDateRange(payLoad:object){
  return this.http.post<any>(`${this.url}/agentAlerts_v2/getCompletedAlertsOnDateRange`,payLoad).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}
CompletedServiceDetails(payLoad:object){
  return this.http.post<any>(`${this.url}/agentAlerts_v2/getServiceCompletedAlerts`,payLoad).pipe(
    catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('A network error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        console.error(`Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // Return an observable with a user-facing error message.
      alert(error)
      return throwError(
        error);
    })
  );    
}

}
