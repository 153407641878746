import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CommoncodesService } from '../_services/commoncodes.service';

@Component({
  selector: 'ngx-share-my-location',
  templateUrl: './share-my-location.component.html',
  styleUrls: ['./share-my-location.component.scss']
})
export class ShareMyLocationComponent implements OnInit {
  qPhone: string;
  qType: string;
  qcaseId: string;
  qServiceId: string;
  scheme: string;
  locationAlert = 'onLoad';
  intervalId: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private commonService: CommoncodesService) { }

  ngOnInit(): void {
    this.qPhone = this.route.snapshot.queryParamMap.get('phone');
    this.qType = this.route.snapshot.queryParamMap.get('type');
    this.qcaseId = this.route.snapshot.queryParamMap.get('caseId');
    this.qServiceId = this.route.snapshot.queryParamMap.get('serviceId');
    this.scheme = this.route.snapshot.queryParamMap.get('scheme');

    if (this.scheme === 're') {
      // Hide element with id 'nonre'
    } else {
      // Hide element with id 're'
    }

    this.intervalId = setInterval(() => this.getLocation(), 1000);
  }

  getLocation(): void {
    if (navigator.geolocation) {
      if (this.locationAlert === 'onLoad') {
        navigator.geolocation.getCurrentPosition(
          position => this.callCustomerLocationAPI(position),
          error => this.showError(error)
        );
      } else {
        navigator.geolocation.getCurrentPosition(
          position => this.callCustomerLocationAPI(position)
        );
      }
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  showError(error): void {
    this.locationAlert = '';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('Your location setting has been turned off. Turn it on to share your location.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
    }
  }

  callCustomerLocationAPI(position): void {
    const data = {
      lat: position.coords.latitude,
      lon: position.coords.longitude,
      phoneNo: this.qPhone,
      type: this.qType,
      source: 'TextSms'
    };

    if (this.qType === 'c') {
      data['caseId'] = this.qcaseId;
    } else {
      data['serviceId'] = this.qServiceId;
    }

    this.commonService.callCustomerLocationAPI(data).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          alert('Location Shared.');
          clearInterval(this.intervalId);
        } else if (response.status === 'failure' && response.message === 'Location Info already Received') {
          alert(response.message);
          clearInterval(this.intervalId);
        } else {
          alert(response.message);
        }
      }
    );
  }
}
