import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { Router } from "@angular/router";

import { User } from "../_models/user";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import * as moment from "moment";
import { error } from "console";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private baseUrl = environment.apiUrl;
  timeDiff: any;
  private refreshTokenInProgress = false;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userName, password, otp, emailId) {
    return this.http
      .post<any>(`${this.baseUrl}api/login/login`, { userName, password, otp })
      .pipe(
        map((user) => {
          if (user && user.status == "success") {
            user.emailId = emailId;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
          } else {
            return user;
          }
        })
      );
  }

  getUserData() {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    console.log(
      "this.isTokenExpired(currentUser.token)",
      this.isTokenExpired(currentUser.token)
    );
    return this.currentUserSubject.asObservable();
  }

  refreshUserData(token: any) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    let tokenExpiration = moment
      .unix(this.getExpirationTime(token))
      .diff(moment(), "minutes");
    if (!this.refreshTokenInProgress && tokenExpiration <= 5) {
      this.refreshTokenInProgress = true;
      let payload = {
        userName: currentUser.userName,
        email: currentUser.emailId,
      };
      return this.http.post<any>(`${this.baseUrl}api/login/refreshToken`, currentUser).pipe(
        tap((data) => {
          currentUser.token = data.token;
          sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
          this.refreshTokenInProgress = false;
          this.currentUserSubject.next(currentUser);
          return this.currentUserSubject.asObservable();
        })
      );

    } else {
      return this.currentUserSubject.asObservable()
    }
  }

  getJwt(data) {
    return this.http
      .post<any>(`${this.baseUrl}api/login/ssoauthentication`, data)
      .pipe(
        map((user) => {
          if (user) {
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user;
          } else {
            return user;
          }
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    sessionStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    //this.router.navigate(['/login']);
    window.location.href = "/login";
  }

  getLoginOtp(payload) {
    let url = `${this.baseUrl}api/login/getOtp`;
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error("A network error occurred:", error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`
          );
        }
        // Return an observable with a user-facing error message.
        alert("Network Error; please try again later.");
        return throwError("Network Error; please try again later.");
      })
    );
  }
  getDataforFirstLogin(payload) {
    let url = `${this.baseUrl}api/login/getDataforFirstLogin`;
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error("A network error occurred:", error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`
          );
        }
        // Return an observable with a user-facing error message.
        alert("Network Error; please try again later.");
        return throwError("Network Error; please try again later.");
      })
    );
  }
  updateDataforFirstLogin(payload) {
    let url = `${this.baseUrl}api/login/updateDataforFirstLogin`;
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error("A network error occurred:", error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`
          );
        }
        // Return an observable with a user-facing error message.
        alert("Network Error; please try again later.");
        return throwError("Network Error; please try again later.");
      })
    );
  }

  refreshJwtToken(payload): Observable<string> {
    let url = `${this.baseUrl}api/login/refreshToken`;
    console.log("came to refreshToken");
    return this.http.post<any>(url, payload);
  }

  isTokenExpired(token: string): boolean {
    const tokenExpiration = moment.unix(this.getExpirationTime(token));
    const currentTime = moment();
    this.timeDiff = tokenExpiration.diff(currentTime, "minutes");
    return moment().isAfter(tokenExpiration);
  }

  getExpirationTime(token: string): number {
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) {
      return 0;
    }
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload.exp;
  }
}
