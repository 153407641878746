import { NbMenuItem } from '@nebular/theme';
export const AGENT_MENU_ITEMS: NbMenuItem[] = [
  
  
 
  {
    title:'Alerts',
    icon: 'bell-outline',
    children:[
      {
      title:'Agent',
      link:'/alerts/agent'
    },
    // {
    //   title:'Vendor Follow Up',
    //   link:'/alerts/vendor-followup'
    // },
    {
      title:'Completed Services',
      link:'/alerts/completed-service'
    },
    
    {
      title:'Deferred Alerts',
      link:'/alerts/deffered-alerts'
    },
    
    {
      title:'Open Cases',
      link:'/alerts/open-cases'
    },    
    {
      title:'All Cases',
      link:'/alerts/all-cases'
    },
    {
      title:'Daily Cases',
      link:'/alerts/daily-cases'
    },
    // {
    //   title:'Microsite',
    //   link:'/alerts/microsite'
    // },
    {
      title:'Denial Services(>2)',
      link:'/alerts/denial-services'
    },
    // {
    //   title:'Timer Details',
    //   link:'/alerts/timer-details'
    // },
    // {
    //   title:'Draft Cases',
    //   link:'/alerts/draft-cases'
    // },
    {
      title:'Vendor Not Assigned Cases',
      link:'/alerts/vendor-not-assigned-case'
    },
    // {
    //   title: 'ChatBot',       
    //   link: '/alerts/chat-bot',
    // },
    // {
    //   title:'External Cases',
    //   link:'/alerts/external-cases'
    // },
    // {
    //   title:'IRSA Cases',
    //   link:'/alerts/irsa-cases'
    // },
    ]
  },
  {
    title: 'Customers',
    icon: 'person-outline',
    children:[
      {
      title:'Create Customer',
      link:'/cases/customers/lists'
    },
    {
      title:'Upload Customer',
      link:'/cases/customers/uploadcustomer'
    } ] 
  },
  {
    title: 'Case Booking',
    icon: 'edit-2-outline',
    link: '/cases/case-booking'   
  } 
 
 
];
