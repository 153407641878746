import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommoncodesService } from '../_services/commoncodes.service';
import {AuthenticationService} from '../_services/authentication.service';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-pwd-change',
  templateUrl: './pwd-change.component.html',
  styleUrls: ['./pwd-change.component.scss']
})


export class PwdChangeComponent implements OnInit {
  @ViewChild('myDialog') myDialog: any;
  chgPassword = {
    currentPswd: '',
    newPswd: '',
    confirmPswd: ''
  };
  LoginData:any;
  userId:any;
  passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
  errMsg = false;
  mismatch = false;
  popup =false;
  constructor(private router: Router, private commonService: CommoncodesService,
    private authService: AuthenticationService) { }
  ngOnInit(): void {
    this.authService.currentUser.subscribe(data => {
      console.log(data);
      this.LoginData = data;
    });
    console.log(this.LoginData);
    if(!this.LoginData){
      this.popup=true;
    }
  }
  ChangePassword() {
    if (this.passwordRegex.test(this.chgPassword.newPswd)) {
      console.log(this.chgPassword+"chgPass");
      for (let key in this.chgPassword) {

        if (this.chgPassword[key] == '' || this.chgPassword[key] === undefined) {

          this.errMsg = true;
          return;
        }
      }
      if (this.chgPassword.newPswd === this.chgPassword.confirmPswd) {
        this.errMsg = false;
        this.mismatch = false;
        let payload = {
          "currentPassword": this.chgPassword.currentPswd,
          "newPassword": this.chgPassword.newPswd
        }
        this.commonService.changePassword(this.LoginData.userName,this.chgPassword.currentPswd, this.chgPassword.newPswd)
          .subscribe(res => {
            if(res.status=="success")
            {
              if(this.chgPassword.currentPswd==this.chgPassword.newPswd){
                 alert("Entered Current password and new password is same")
              }
              else{
                  alert("Password has been changed successfully");
                   this.authService.logout();
                   this.myDialog.close();
              }
            }
            else{
              alert(res.message);
            }
          });
      } else {
        alert("Confirm password must match with new password");
      }
    } else {
      alert("Kindly check the note points");
    }
  }
 

}
