import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import { USER_ROLES } from './Constants';

const userRoles = ["admin", "regional manager", "vendor", "agent", "network head", "legal team", "finance team", "supervisor", "external_user_role", "it team", "vms team"];

export const ROLE_AGENT = USER_ROLES.AGENT_ROLE      /*"agent" */
export const ROLE_ADMIN = USER_ROLES.ADMIN_ROLE /*"admin" */;
export const ROLE_RM =  USER_ROLES.RM_ROLE/*"regional manager"*/;
export const ROLE_VENDOR =  "vendor";
export const ROLE_NETWORK_HD = USER_ROLES.NETWORK_HEAD_ROLE /*"network head"*/;
export const ROLE_LEGAL = USER_ROLES.LEGAL_ROLE /*"legal team"*/;
export const ROLE_FINANCE_TEAM =  USER_ROLES.FINANCE_ROLE/*"finance team"*/;
export const ROLE_IT_TEAM =  USER_ROLES.IT_TEAM_ROLE/*"it team"*/;
export const ROLE_SUPERVISOR =  USER_ROLES.SUPERVISOR_ROLE/*"supervisor"*/;
export const ROLE_VMS_TEAM =  USER_ROLES.VMS_TEAM_ROLE/*"vms team"*/;
export const ROLE_ZM =  USER_ROLES.ZONAL_ROLE/*"zonal manager"*/;
export const ROLE_TM =  USER_ROLES.TERRITORY_ROLE/*"territory manager"*/;

export const ALERT_MODULE_ROLES = [ROLE_AGENT, ROLE_RM, ROLE_NETWORK_HD, ROLE_FINANCE_TEAM, ROLE_VENDOR, ROLE_LEGAL];
export const CASES_MODULE_ROLES = [ROLE_AGENT, ROLE_RM, ROLE_NETWORK_HD, ROLE_FINANCE_TEAM,ROLE_SUPERVISOR,ROLE_ZM,ROLE_TM];
export const VENDOR_MODULE_ROLES = [ROLE_RM, ROLE_NETWORK_HD, ROLE_FINANCE_TEAM,ROLE_ZM,ROLE_TM];
export const FINANCE_MODULE_ROLES = [ROLE_FINANCE_TEAM, ROLE_NETWORK_HD, ROLE_VENDOR];
export const SUPERVISOR_MODULE_ROLES = [ROLE_SUPERVISOR];
export const RM_MODULE_ROLES = [ROLE_RM, ROLE_NETWORK_HD, ROLE_FINANCE_TEAM, ROLE_VENDOR,ROLE_ZM,ROLE_TM];
export const ADMIN_MODULE_ROLES = [ROLE_ADMIN, ROLE_RM, ROLE_NETWORK_HD, ROLE_FINANCE_TEAM];


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        const expectedRole = route.data.expectedRole;
        if (currentUser && expectedRole.includes(currentUser.role)) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        this.authenticationService.logout();
        return false;
    }
}