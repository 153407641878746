<html lang="en" class="ng-scope">

<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    <meta content="utf-8" http-equiv="encoding">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>:: E-Z Auto ::</title>
    <meta name="description" content="Europ assistance share location">
    <meta name="author" content="Softforce">

    <style>
        body {
            font-family: Arial, Helvetica, sans-serif !important;
            background-color: #d4e1f9;
            margin: 0 auto;

        }
.logo-ea{
text-align: center;
}
.logo-ea img{
    width: 186px;
    padding: 30px 0px; 
}
 
    </style>
</head>

<body>
    <!-- <div class="header">Europ Assistance</div>-->
 
   
    <div class="text-center" id='nonre'>
        <div class="logo-ea">
        <img src="assets/images/eai-logo.png"
                class="rounded  " style="height:20% !important;">
 
</div>
</div>
</body>
</html>